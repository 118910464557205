export enum SharePointApplicationType {
  KEY_REQUEST_TYPE = 'KEYS',
  TROUBLE_TICKET_TYPE = 'TT',
  LER_TYPE = 'LER',
  SUBSTATION_ENTRY_TYPE = 'SUBSTATION_LOG',
  FORCED_OUTAGE = 'FORCED_OUTAGE',
}

export enum SharePointDownloadType {
  MCC_SHAREPOINT_TYPE = 'MCC Sharepoint',
}

export enum SharePointAccessTokenSession {
  SHAREPOINT_ACCESS_TOKEN = 'SharePoint_Access_Token',
}
