import React from 'react';
import { ATMGrid, ATMHeader, ATMIcon, ATMTable } from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { IForcedOutagePayload } from 'src/models/forced-outage.model';
import FieldReadOnly from 'src/components/atoms/field/field-readonly/field-readonly.component';
import styles from './forced-outage-restoration-information.module.scss';
import moment from 'moment';
import { OutageInterval } from 'src/constants/forced-outage.constants';

type IProps = {
  data: IForcedOutagePayload;
};
const ForcedOutageRestorationInformation: React.FC<IProps> = ({ data }) => {
  const restorationSub = (
    <>
      <ATMGrid>
        <ATMGrid.Column>
          <ATMTable>
            <ATMTable.Header>
              <ATMTable.Row>
                <ATMTable.HeaderCell content="Substation" />
              </ATMTable.Row>
            </ATMTable.Header>
            <ATMTable.Body>
              {data?.restorationInformation?.restorationSub &&
              data?.restorationInformation?.restorationSub?.length > 0 ? (
                data.restorationInformation.restorationSub.map((item, i) => (
                  <ATMTable.Row key={i}>
                    <ATMTable.Cell textAlign="left">
                      {item?.substation?.name ?? null}
                    </ATMTable.Cell>
                  </ATMTable.Row>
                ))
              ) : (
                <ATMTable.Row textAlign="center">
                  <ATMTable.Cell>
                    {Lang.MSG_NOTIFICATION_BLOCK_EMPTY}
                  </ATMTable.Cell>
                </ATMTable.Row>
              )}
            </ATMTable.Body>
          </ATMTable>
        </ATMGrid.Column>
      </ATMGrid>
    </>
  );
  const content = (
    <>
      <span>
        <ATMIcon circular name="clipboard list" />
      </span>
      <ATMHeader
        as="h2"
        content={Lang.LBL_FORCED_OUTAGE_RESTORATION_INFORMATION}
        className={styles.header}
      />
      <ATMGrid columns={2}>
        <ATMGrid.Row>
          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_FORCED_OUTAGE_LOAD_RESTORATION_DATE}>
              {data?.restorationInformation?.loadRestoreDt
                ? moment(data.restorationInformation.loadRestoreDt).format(
                    'MM/DD/YYYY'
                  )
                : null}
            </FieldReadOnly>
          </ATMGrid.Column>
          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_FORCED_OUTAGE_LOAD_DROP_DURATION}>
              {data?.restorationInformation?.loadDropDuration}
            </FieldReadOnly>
          </ATMGrid.Column>
          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_FORCED_OUTAGE_LOAD_DROPPED}>
              {data?.restorationInformation?.loadDroppped ? 'Yes' : 'No'}
            </FieldReadOnly>
          </ATMGrid.Column>
          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_FORCED_OUTAGE_INTERVAL}>
              {data?.duration && data.duration > 5
                ? OutageInterval.Sustained
                : OutageInterval.Momentary}
            </FieldReadOnly>
          </ATMGrid.Column>
          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_FORCED_OUTAGE_HFTD_CLASS}>
              {data?.restorationInformation?.hftd ?? null}
            </FieldReadOnly>
          </ATMGrid.Column>
          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_FORCED_FACILITY_TYPE}>
              {Lang.FACILITY_TYPE[data?.facilityTypeId ?? ''] ?? null}
            </FieldReadOnly>
          </ATMGrid.Column>
          <ATMGrid.Column>
            <FieldReadOnly label={Lang.LBL_FORCED_OUTAGE_INITIATING_ROW_ITEM}>
              {data?.restorationInformation?.initiatingRowItem ? 'Yes' : 'No'}
            </FieldReadOnly>
          </ATMGrid.Column>
        </ATMGrid.Row>
      </ATMGrid>
    </>
  );
  return (
    <div>
      {content}
      {restorationSub}
    </div>
  );
};

export default ForcedOutageRestorationInformation;
