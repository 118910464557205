import React, { useCallback, useState } from 'react';
import { Confirm } from 'semantic-ui-react';
import { ATMButton } from 'shared-it-appmod-ui';
import Access from 'src/components/atoms/access/access.component';
import { AccessRole } from 'src/constants';
import { ForcedOutageStatus } from 'src/constants/forced-outage.constants';
import { useForcedOutageContext } from 'src/contexts/forced-outage.context';
import { forcedOutageListActionTypes } from 'src/ducks/forced-outage.duck';
import { AccessType, hasRole } from 'src/libraries/access.library';
import Lang from 'src/libraries/language';
import { IForcedOutagePayload } from 'src/models/forced-outage.model';
import { getForcedOutageStatus } from 'src/selectors/forced-outage.selector';

type IProps = {
  id: number;
  trigger?: React.ReactNode;
  data?: IForcedOutagePayload;
};

const ForcedOutageApprove: React.FC<IProps> = ({ id, data }) => {
  const { state, actions } = useForcedOutageContext();
  const temporarilyDisable = true;
  const status = getForcedOutageStatus(
    state,
    forcedOutageListActionTypes.FORCED_OUTAGE_DATA_APPROVE
  );
  const [isConfirm, setIsConfirm] = useState(false);

  const handleApprove = useCallback(async () => {
    await actions.approvePUT(id);
  }, [actions]);

  return (
    <>
      <Access
        type={AccessType.FORCED_OUTAGE_GOS}
        when={
          hasRole(AccessRole.FORCED_OUTAGE_RESOLVER) &&
          [ForcedOutageStatus.UnderReviewGOS].includes(
            data?.status as ForcedOutageStatus
          )
        }
      >
        <>
          <Confirm
            open={isConfirm}
            size="small"
            onConfirm={handleApprove}
            onOpen={() => setIsConfirm(true)}
            onCancel={() => setIsConfirm(false)}
            trigger={
              <ATMButton
                positive
                type="button"
                content={Lang.LBL_FORCED_OUTAGE_APPROVE}
                disabled={temporarilyDisable || status.fetching}
                loading={status.fetching}
              />
            }
            header={`${Lang.LBL_FORCED_OUTAGE_APPROVE} Forced Outage`}
            content={Lang.MSG_FORCED_OUTAGE_APPROVE_CONFIRMATION}
            loading={status.fetching}
          />
        </>
      </Access>
    </>
  );
};

export default ForcedOutageApprove;
