import React, { useCallback, useEffect, useState } from 'react';
import {
  useATMFormContext,
  ATMIcon,
  ATMHeader,
  ATMGrid,
  ATMField,
  ATMInput,
  ATMDatePicker,
  ATMSelect,
  formatTime,
  ATMDropdown,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import {
  IForcedOutage,
  IForcedOutageForm,
} from 'src/models/forced-outage.model';
import { LERRequestWidth } from 'src/constants';
import ForcedOutagePanel from '../foced-outage-panel/forced-outage-panel.component';
import style from './forced-outage-restoration-information.module.scss';
import ForcedOutageRestorationSubForm from './forced-outage-restoration-sub/forced-outage-restoration-sub-form/forced-outage-restoration-sub-form.component';
import LabelRequired from 'src/components/atoms/label-required/label-required.component';
import {
  restrictAlphabetsAndSpecialChars,
  HourTimePattern,
} from 'src/components/atoms/input/time-input-switching-format.component';
import { debounce } from 'lodash';
import moment from 'moment';
import { useSubstationContext } from 'src/contexts/substation.context';
import { getSubstationStatus } from 'src/selectors/substation.selector';
import { substationActionTypes } from 'src/ducks/substation.duck';

export type IProps = {
  setFileCached?: (data: boolean) => void;
  data?: IForcedOutage | null;
  isEdit?: boolean;
  isUpdated?: boolean;
};

const FormContent: React.FC = () => {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
    clearErrors,
  } = useATMFormContext<IForcedOutageForm>();
  const { state: substationState } = useSubstationContext();
  const substationStatus = getSubstationStatus(
    substationState,
    substationActionTypes.SUBSTATION_LIST_CREATE_READ
  );
  const dropdownOptions = [
    { key: 1, value: true, text: 'True' },
    { key: 2, value: false, text: 'False' },
  ];

  const [isLoadDropped, setIsLoadDropped] = useState<boolean | undefined>();
  const [loadRestoreTm, setLoadRestoreTm] = useState<
    string | undefined | null
  >();
  const [loadDropDuration, setLoadDropDuration] = useState<
    string | undefined | null
  >();

  const [subDropped, setSubDropped] = useState<string[]>();

  useEffect(() => {
    if (
      getValues().restorationInformation &&
      getValues().restorationInformation.substationDropped
    ) {
      const subs = JSON.parse(
        getValues()?.restorationInformation?.substationDropped ?? ''
      );
      setSubDropped(subs);
    }
  }, [setSubDropped]);

  const handleTimeUpdate = useCallback(
    debounce((value, name) => {
      const input = document.getElementsByName(name)[0] as HTMLInputElement;
      const nativeInputValueSetter = Object?.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        'value'
      )?.set;
      nativeInputValueSetter?.call(input, value);
      const ocEvent = new Event('input', { bubbles: true });
      input?.dispatchEvent(ocEvent);

      if (input && value) {
        input.setSelectionRange(value.length, value.length);
      }
      input?.focus();
    }, 50),
    []
  );

  const durationComputation = () => {
    const startDate = getValues().startDate;
    const startTm = getValues().startTm;
    const endDate = getValues().restorationInformation.loadRestoreDt;
    const endTm = loadRestoreTm;

    if (endTm) {
      const [startHr, startMin] = startTm.split(':');
      const [endHr, endMin] = endTm.split(':');

      const start = moment(startDate)
        .add('hours', startHr)
        .add('minutes', startMin) as any;
      const end = moment(endDate)
        .add('hours', endHr)
        .add('minutes', endMin) as any;

      const computedMinutes = end.diff(start, 'minutes');

      const hours = Math.floor(computedMinutes / 60);
      const minutes = computedMinutes % 60;

      // Pad with leading zeros if necessary
      const formattedHours = String(hours).padStart(2, '0');
      const formattedMinutes = String(minutes).padStart(2, '0');

      setValue('restorationInformation.loadDropDuration', computedMinutes);
      setLoadDropDuration(`${formattedHours}:${formattedMinutes}`);

      setValue('restorationInformation.loadRestoreDt', moment(end).toDate());
    } else {
      setLoadDropDuration('00:00');
    }
  };

  return (
    <>
      <span className={style.iconRight}>
        <ATMIcon circular name="clipboard list" />
      </span>
      <ATMHeader
        as="h2"
        content={Lang.TTL_FORCED_OUTAGE_RESTORATION}
        className={style.header}
      />
      <ATMGrid columns={2} style={{ maxWidth: 1023 }}>
        <ATMGrid.Column>
          <ATMGrid columns={2}>
            <ATMGrid.Column>
              <ATMField
                name="restorationInformation.loadDroppped"
                as={ATMSelect}
                selection
                label={
                  <LabelRequired>
                    {Lang.LBL_FORCED_OUTAGE_LOAD_DROPPED}
                  </LabelRequired>
                }
                control={control}
                clearable
                options={dropdownOptions}
                selectOnBlur={false}
                onChange={([_, { value }]) => {
                  setIsLoadDropped(value);
                  return value;
                }}
              />
            </ATMGrid.Column>
            <ATMGrid.Column>
              <ATMField
                name="restorationInformation.loadRestoreDt"
                as={ATMDatePicker}
                isDateTimePicker
                minDate={getValues().startDate}
                format="MM/DD/YYYY"
                dateTimePlaceHolder="Select"
                disabled={!isLoadDropped}
                label={Lang.LBL_FORCED_OUTAGE_LOAD_RESTORATION_DATE}
                control={control}
                onChange={([_, { value }]) => {
                  return value;
                }}
                selectOnBlur={false}
              />
            </ATMGrid.Column>
          </ATMGrid>
        </ATMGrid.Column>

        <ATMGrid.Column>
          <ATMGrid columns={2}>
            <ATMGrid.Column>
              <ATMField
                name="loadRestoreTm"
                as={ATMInput}
                label={Lang.LBL_FORCED_OUTAGE_LOAD_RESTORATION_TIME}
                control={control}
                autoComplete="off"
                maxLength={5}
                error={errors.startTm}
                disabled={!isLoadDropped}
                placeholder="hh:mm"
                clearable
                onChange={([_, { value }]) => {
                  let formattedTime =
                    value && value.length > 3
                      ? formatTime(restrictAlphabetsAndSpecialChars(value))
                      : restrictAlphabetsAndSpecialChars(value);

                  if (formattedTime) {
                    if (formattedTime.length > 4) {
                      handleTimeUpdate(formattedTime, 'loadRestoreTm');
                    }

                    if (
                      formattedTime.match(
                        HourTimePattern.HOUR_TIME_PATTERN as any
                      )
                    ) {
                      const hour = Number(formattedTime.split(':')[0]);
                      const min = Number(formattedTime.split(':')[1]);
                      if (!(hour > 23 || min > 59)) {
                        formattedTime = `${hour}:${min}`;
                        durationComputation();
                      } else {
                        formattedTime = null;
                        setLoadRestoreTm(null);
                      }
                    } else {
                      formattedTime = null;
                      setLoadRestoreTm(null);
                    }
                  }

                  setLoadRestoreTm(formattedTime);
                  return formattedTime;
                }}
              />
            </ATMGrid.Column>
            <ATMGrid.Column>
              <ATMField
                name="loadDropDuration"
                as={ATMInput}
                readOnly
                disabled
                label={Lang.LBL_FORCED_OUTAGE_LOAD_DROP_DURATION}
                placeholder="hh:mm"
                value={loadDropDuration}
              />
            </ATMGrid.Column>
          </ATMGrid>
        </ATMGrid.Column>

        <ATMGrid.Column>
          <ATMField
            name="restorationInformation.initiatingRowItem"
            as={ATMSelect}
            control={control}
            clearable
            selection
            label={Lang.LBL_FORCED_OUTAGE_INITIATING_ROW_ITEM}
            options={[
              { key: 1, value: true, text: 'Yes' },
              { key: 2, value: false, text: 'No' },
            ]}
            selectOnBlur={false}
            onChange={([_, { value }]) => {
              return value;
            }}
          />
        </ATMGrid.Column>

        <ATMGrid.Column>
          <ATMField
            name="substationDropped"
            as={ATMDropdown}
            // control={control}
            value={subDropped || []}
            selection
            clearable
            multiple
            search
            placeholder="Select"
            loading={substationStatus.fetching}
            label={Lang.LBL_FORCED_OUTAGE_SUBSTATION_DROPPED}
            options={substationState.listForCreate.map((value) => ({
              key: value.substationId,
              value: value.substationId,
              text: value.name,
            }))}
            selectOnBlur={false}
            onChange={(_, { value }) => {
              clearErrors('restorationInformation.substationDropped');
              if (value) {
                setSubDropped(value);
                setValue(
                  'restorationInformation.substationDropped',
                  JSON.stringify(value)
                );
              } else {
                setSubDropped([]);
              }

              return value;
            }}
          />
        </ATMGrid.Column>

        <ATMGrid.Column width={16}>
          <ForcedOutageRestorationSubForm />
        </ATMGrid.Column>
      </ATMGrid>
    </>
  );
};

const ForcedOutageRestorationForm: React.FC<IProps> = ({
  setFileCached,
  data,
  isEdit,
  isUpdated,
}) => {
  return (
    <ATMGrid divided>
      <ATMGrid.Column width={LERRequestWidth.LEFT}>
        <FormContent />
      </ATMGrid.Column>
      <ATMGrid.Column width={LERRequestWidth.RIGHT}>
        <ForcedOutagePanel
          isEdit={isEdit}
          data={data}
          setFileCached={setFileCached}
          isUpdated={isUpdated}
        />
      </ATMGrid.Column>
    </ATMGrid>
  );
};

export default ForcedOutageRestorationForm;
