import moment from 'moment';
import {
  IForcedOutage,
  IForcedOutagePayload,
} from 'src/models/forced-outage.model';

export const getDuration = (data: IForcedOutage | IForcedOutagePayload) => {
  const formattedTime = moment
    .utc()
    .startOf('day')
    .add(data.duration, 'minutes')
    .format('HH:mm');
  if (formattedTime) {
    if (formattedTime.includes('00:00')) {
      return '';
    }
    return formattedTime;
  }
  return '';
};

export const durationComputation = (
  startDate: Date,
  startTm: string,
  endDate: Date,
  endTm: string
) => {
  if (startDate && startTm && endDate && endTm) {
    const [startHr, startMin] = startTm.split(':');
    const [endHr, endMin] = endTm.split(':');

    const start = moment(startDate)
      .add('hours', startHr)
      .add('minutes', startMin) as any;
    const end = moment(endDate)
      .add('hours', endHr)
      .add('minutes', endMin) as any;

    const computedMinutes = end.diff(start, 'minutes');

    const hours = Math.floor(computedMinutes / 60);
    const minutes = computedMinutes % 60;

    // Pad with leading zeros if necessary
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
  } else {
    return '--';
  }
};
