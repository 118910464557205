import React, { useCallback, useEffect, useState } from 'react';
import {
  ATMButton,
  ATMGrid,
  ATMHeader,
  ATMIcon,
  ATMTable,
} from 'shared-it-appmod-ui';
import { useFileContext } from 'src/contexts/file.context';
import Lang from 'src/libraries/language';
import styles from '../forced-outage-overview/forced-outage-overview.module.scss';
import FileUploader from 'src/components/atoms/file-uploader/file-uploader.component';
import { IFileSharePoint } from 'src/models/file.model';
import FileDelete from 'src/components/atoms/file-delete/FileDelete';
import { useSettingContext } from 'src/contexts/setting.context';
import { SharePointApplicationType } from 'src/constants/sharepoint-common.constant';
import {
  SharePointSubmitRequestType,
  useSharepoint,
} from 'src/hooks/file.hook';
import { ISharepointRequestData } from 'src/models/sharepoint-service.model';
import { ToastError } from 'src/components/atoms/toaster/toaster.component';
import { IForcedOutage } from 'src/models/forced-outage.model';

export type IProps = {
  tab?: string;
  data?: IForcedOutage | null;
  mccListFile?: IFileSharePoint[];
  setFileCached?: (data: boolean) => void;
  isEdit?: boolean;
  isUpdated?: boolean;
  setLerDocuments?: React.Dispatch<
    React.SetStateAction<IFileSharePoint[] | undefined>
  >;
  setIsSharepointError?: React.Dispatch<React.SetStateAction<boolean>>;
  setMccListFile?: React.Dispatch<React.SetStateAction<IFileSharePoint[]>>;
};

const ForcedOutageDocument: React.FC<IProps> = ({
  setFileCached,
  setLerDocuments,
  // setMccListFile,
  data,
  isEdit,
  // mccListFile,
  isUpdated,
}) => {
  const { actions, state } = useFileContext();
  const { state: settingState } = useSettingContext();
  const { fetchMccList } = useSharepoint();
  const [mccListFile, setMccListFile] = useState<IFileSharePoint[]>([]);
  const [isSharepointError, setIsSharepointError] = useState<boolean>(false);
  const [, setListFile] = useState<IFileSharePoint[]>([]);

  useEffect(() => {
    setListFile(state.filename_list ?? []);
  }, [state.stash]);

  const getMccList = useCallback(async () => {
    let request: SharePointSubmitRequestType = {} as any;
    const requestData: ISharepointRequestData = {
      accessToken: '',
      createdBy: '',
      documentId: '',
      fileId: '',
      fileName: '',
      folderName: data?.outageId?.replace(/"/gi, '') || '',
      parentFolder: '',
      requestId: data?.outageId as any,
      requestor: '',
      documentLibrary: '',
    };
    request = {
      applicationType: SharePointApplicationType.FORCED_OUTAGE,
      requestData,
      setMccListFile,
      withParentFolder: false,
      setting: settingState,
      files: state.stash,
    };
    const files = await fetchMccList(request);

    if (files === null) {
      ToastError('Unable to connect to Sharepoint at the moment.');
    }

    if (setLerDocuments) {
      setLerDocuments(files?.fileList ?? []);
    }

    if (setIsSharepointError && files.withError) {
      setIsSharepointError(files.withError);
    }
  }, [
    settingState,
    fetchMccList,
    setMccListFile,
    setLerDocuments,
    setIsSharepointError,
  ]);

  useEffect(() => {
    if (data && (isUpdated || (!mccListFile.length && isEdit))) {
      getMccList();
    }
  }, [data, isUpdated, isEdit]);

  const forcedOutageDocument = (
    <>
      <ATMGrid columns={2} style={{ maxWidth: 800 }}>
        <ATMGrid.Column>
          <span className={styles.iconRight}>
            <ATMIcon circular name="file alternate" />
          </span>
          <ATMHeader
            as="h2"
            content={Lang.TTL_DOCUMENTS}
            className={styles.header}
          />
        </ATMGrid.Column>
        <ATMGrid.Column textAlign="right">
          <FileUploader
            icon="upload"
            onSuccess={() => {
              setFileCached && setFileCached(true);
            }}
            text={Lang.LBL_UPLOAD}
            successMessage="Your file(s) has been cached, but will not be uploaded until the Forced Outage has been Created/Submitted"
            errorMessage="Failed to cache file(s)"
            displayRemoveButton={false}
            hasSharePointError={isSharepointError}
            popOverPostition="bottom right"
            popOverStyle={{ color: 'red', width: '200px' }}
          />
        </ATMGrid.Column>
      </ATMGrid>
      {state.filename_list?.map((item, i) => (
        <span key={i}>
          <ATMButton
            type="button"
            icon="close"
            onClick={() => {
              actions.removeFileFromStash(item);
            }}
          />
          {item}
        </span>
      ))}
      <ATMTable>
        <ATMTable.Header>
          <ATMTable.Row>
            <ATMTable.HeaderCell>{Lang.LBL_FILE_NAME}</ATMTable.HeaderCell>
            <ATMTable.HeaderCell>{Lang.LBL_UPLOADED_BY}</ATMTable.HeaderCell>
            {isEdit && (
              <ATMTable.HeaderCell>{Lang.LBL_ACTION}</ATMTable.HeaderCell>
            )}
          </ATMTable.Row>
        </ATMTable.Header>
        <ATMTable.Body>
          {mccListFile && mccListFile.length ? (
            mccListFile?.map((item, i) => (
              <ATMTable.Row key={i}>
                <ATMTable.Cell>
                  <>
                    <a
                      key={item.fileUrl as any}
                      href={item.fileUrl as any}
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      {item.fileName}
                    </a>
                  </>
                </ATMTable.Cell>
                <ATMTable.Cell>{item.createdBy}</ATMTable.Cell>
                {isEdit && (
                  <ATMTable.Cell>
                    <FileDelete
                      file={item}
                      displayConfirmation
                      handleSuccess={() => getMccList()}
                    />
                  </ATMTable.Cell>
                )}
              </ATMTable.Row>
            ))
          ) : (
            <>
              {isEdit ? (
                <ATMTable.Row textAlign="center">
                  <ATMTable.Cell />
                  <ATMTable.Cell>
                    {Lang.MSG_NOTIFICATION_BLOCK_EMPTY}
                  </ATMTable.Cell>
                  <ATMTable.Cell />
                </ATMTable.Row>
              ) : (
                <ATMTable.Row>
                  <ATMTable.Cell />
                  <ATMTable.Cell>
                    {Lang.MSG_NOTIFICATION_BLOCK_EMPTY}
                  </ATMTable.Cell>
                </ATMTable.Row>
              )}
            </>
          )}
        </ATMTable.Body>
      </ATMTable>
    </>
  );

  return <>{forcedOutageDocument}</>;
};

export default ForcedOutageDocument;
