import { IORGDataTableQueryState } from 'shared-it-appmod-ui';
import { httpClient } from 'src/libraries/http.library';
import {
  CauseCodeSchema,
  ForcedOutageListPayloadSchema,
  ForcedOutagePayloadSchema,
  IForcedOutageForm,
} from 'src/models/forced-outage.model';

const client = httpClient();
const endpoint = 'forced-outage';

const services = {
  draftPOST: async (data: IForcedOutageForm) => {
    return client.post(`/${endpoint}`, data, ForcedOutagePayloadSchema);
  },

  draftPUT: async (id: number, data: IForcedOutageForm) => {
    return client.put(
      `/${endpoint}?action=draft/${id}`,
      data,
      ForcedOutagePayloadSchema
    );
  },

  createPOST: async (data: IForcedOutageForm) => {
    return client.post(`/${endpoint}`, data, ForcedOutagePayloadSchema);
  },

  dataGET: async (id: number) => {
    return client.get(`/${endpoint}/${id}`, {}, ForcedOutagePayloadSchema);
  },

  updatePUT: async (id: number, data: IForcedOutageForm) => {
    return client.put(`/${endpoint}/${id}`, data, ForcedOutagePayloadSchema);
  },

  approvePUT: async (id: number) => {
    return client.put(
      `/${endpoint}/${id}/?action=approve`,
      {},
      ForcedOutagePayloadSchema
    );
  },

  rejectPUT: async (id: number) => {
    return client.put(
      `/${endpoint}/${id}/?action=reject`,
      {},
      ForcedOutagePayloadSchema
    );
  },

  listGET: async (data: Partial<IORGDataTableQueryState> = {}) => {
    return client.get(`/${endpoint}`, data, ForcedOutageListPayloadSchema);
  },

  getListCauseCd: async () => {
    return client.get(`/${endpoint}/?action=cause-code`, {}, CauseCodeSchema);
  },
};

export default services;
