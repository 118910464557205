import React from 'react';
import {
  ATMHeader,
  ATMIcon,
  ATMSegment,
  MOLInfoCard,
} from 'shared-it-appmod-ui';
import styles from './forced-outage-comments.module.scss';
import { IForcedOutagePayload } from 'src/models/forced-outage.model';
import moment from 'moment';
import Lang from 'src/libraries/language';

type IProps = {
  data: IForcedOutagePayload;
};

const ForcedOutageCommentsDetail: React.FC<IProps> = ({ data }) => {
  return (
    <>
      <div className={styles.headerWrapper}>
        <span className={styles.iconRight}>
          <ATMIcon circular name="comments" />
        </span>
        <ATMHeader
          as="h2"
          content={Lang.TTL_FORCED_OUTAGE_COMMENTS}
          className={styles.header}
        />
      </div>
      <div className={styles.comment}>
        {data?.outageComments?.length ? (
          data.outageComments?.map((val, i) => (
            <MOLInfoCard
              key={i}
              iconName="user"
              iconColor="grey"
              showIcon
              cardHeader={`${val?.updatedByEmployee?.firstName} ${val?.updatedByEmployee?.lastName}`}
              cardSubHeader={moment(val?.updatedAt)
                .format('HH:mm A MM/DD/YYYY')
                .toString()}
              cardContent={val?.comment ?? ''}
            />
          ))
        ) : (
          <ATMSegment
            style={{
              color: '#666666',
              display: 'flex',
              justifyContent: 'center',
            }}
            content={Lang.LBL_FORCED_OUTAGE_NO_COMMENTS}
          />
        )}
      </div>
    </>
  );
};

export default ForcedOutageCommentsDetail;
