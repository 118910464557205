import { ICauseCode } from 'src/models/forced-outage.model';

export const causeCodeValues: ICauseCode[] = [
  { causeCd: 1010, description: 'Flashover Insulator (non-wash)' },
  { causeCd: 1011, description: 'Flashover Insulator, to be patrolled' },
  { causeCd: 1020, description: 'Flashover Insulator (during wash)' },
  { causeCd: 1030, description: 'Flshovr Condctr, Static, Guy (wash)' },
  { causeCd: 1040, description: 'Flashover Switch, Disconnect' },
  { causeCd: 1050, description: 'Flashover due to overbuild, underbuild' },
  { causeCd: 1060, description: 'Flshovr Condctr, Static, Guy (non-wash)' },
  { causeCd: 2010, description: 'Lightning' },
  { causeCd: 2020, description: 'Wind' },
  { causeCd: 2030, description: 'Fog' },
  { causeCd: 2040, description: 'Fire' },
  { causeCd: 2050, description: 'Rain' },
  { causeCd: 2060, description: 'Flood/Washout' },
  { causeCd: 2070, description: 'Ice/Snow' },
  { causeCd: 2080, description: 'Landslide' },
  { causeCd: 2090, description: 'Seismic' },
  { causeCd: 2100, description: 'Soil' },
  { causeCd: 2999, description: 'Other Natural' },
  { causeCd: 3010, description: 'Tree' },
  { causeCd: 3020, description: 'Weeds, Plants' },
  { causeCd: 3030, description: 'Foreign Object (e.g. Balloons, Kites)' },
  { causeCd: 3040, description: 'Birds, Nests' },
  { causeCd: 3050, description: 'Reptiles' },
  { causeCd: 3060, description: 'Animals' },
  { causeCd: 3070, description: 'Aircraft' },
  { causeCd: 3080, description: 'Vandalism' },
  { causeCd: 3090, description: 'Gun Shot' },
  { causeCd: 3100, description: 'Human - Co. Personnel' },
  { causeCd: 3110, description: 'Human - Non-Co. Personnel' },
  { causeCd: 3120, description: 'Vehicle - Co. Personnel' },
  { causeCd: 3130, description: 'Vehicle - Non-Co. Personnel' },
  { causeCd: 3999, description: 'Other Contact' },
  { causeCd: 4010, description: 'Relay Crew' },
  { causeCd: 4020, description: 'Maintenance Crew' },
  { causeCd: 4030, description: 'Troubleman' },
  { causeCd: 4040, description: 'Wash Crew (non-flashover)' },
  { causeCd: 4050, description: 'Operator' },
  { causeCd: 4060, description: 'Construction Crew' },
  { causeCd: 4999, description: 'Other Crew Error' },
  { causeCd: 5010, description: 'Disconnect' },
  { causeCd: 5020, description: 'Relay' },
  { causeCd: 5030, description: 'Circuit Breaker' },
  { causeCd: 5040, description: 'Insulator' },
  { causeCd: 5050, description: 'PT / CT' },
  { causeCd: 5060, description: 'Transformer' },
  { causeCd: 5070, description: 'Pilot Wire' },
  { causeCd: 5080, description: 'Connector' },
  { causeCd: 5090, description: 'Surge Arrestor' },
  { causeCd: 5100, description: 'Capacitor, Shunt' },
  { causeCd: 5110, description: 'Conductor' },
  { causeCd: 5120, description: 'Mounting Hardware' },
  { causeCd: 5130, description: 'Distribution Equipment' },
  { causeCd: 5999, description: 'Other Substation Equipment' },
  { causeCd: 6010, description: 'Insulator (non-flashover)' },
  { causeCd: 6020, description: 'Conductor' },
  { causeCd: 6030, description: 'Pole' },
  { causeCd: 6040, description: 'Tower' },
  { causeCd: 6050, description: 'Crossarm' },
  { causeCd: 6060, description: 'Guy' },
  { causeCd: 6070, description: 'Static Wire' },
  { causeCd: 6080, description: 'Underground Cable Fault' },
  { causeCd: 6090, description: 'Mounting Hardware' },
  { causeCd: 6100, description: 'Line Switch' },
  { causeCd: 6110, description: 'Distribution Equipment' },
  { causeCd: 6120, description: 'Line Disconnect' },
  { causeCd: 6999, description: 'Other Transmission Equipment' },
  { causeCd: 7010, description: 'Overload' },
  { causeCd: 7020, description: 'Relay Misoperation' },
  { causeCd: 7030, description: 'Switching Surge' },
  { causeCd: 7040, description: 'Explosion (non-electrical)' },
  { causeCd: 7050, description: 'Customer Equipment' },
  { causeCd: 7060, description: 'Other Utilities' },
  { causeCd: 7999, description: 'Other Overload/Protection' },
  { causeCd: 8010, description: 'Transmssn Equip Repair/Replacement' },
  { causeCd: 8020, description: 'Substation Equip Repair/Replacement' },
  { causeCd: 8030, description: 'Contact Removal' },
  { causeCd: 8040, description: 'Safety' },
  { causeCd: 8050, description: 'Load Transfer' },
  { causeCd: 8060, description: 'Adjustment (e.g. reseat disconnect)' },
  {
    causeCd: 8070,
    description: 'Non-Emer Scheduled Outage (< 72 hrs adv notice)',
  },
  { causeCd: 8999, description: 'Other Emergency Scheduled Outage' },
  { causeCd: 9100, description: 'Undetermined - Lightning in area' },
  { causeCd: 9200, description: 'Undetermined - After Patrol' },
  { causeCd: 9998, description: 'Undetermined - to be patrolled' },
  { causeCd: 9999, description: 'Undetermined' },
];
