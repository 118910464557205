import React, { useCallback, useEffect, useRef } from 'react';
import { useFieldArray } from 'react-hook-form';
import {
  ATMButton,
  ATMField,
  ATMForm,
  ATMFormProvider,
  ATMGrid,
  ATMHeader,
  ATMIcon,
  ATMInput,
  ATMSelect,
  ATMTable,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import {
  IForcedOutageForm,
  ISubFaultRelayForm,
  SubFaultRelayFormSchema,
} from 'src/models/forced-outage.model';
import { useSubstationContext } from 'src/contexts/substation.context';
import style from './forced-outage-sub-fault-relay.module.scss';
import { ToastError } from 'src/components/atoms/toaster/toaster.component';

const FormContent: React.FC<{
  formRef?: React.RefObject<HTMLFormElement>;
}> = ({ formRef }) => {
  const {
    control,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useATMFormContext<ISubFaultRelayForm>();
  const { state: substationState } = useSubstationContext();

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  useEffect(() => {
    reset({
      substation: '',
      faultMi: '',
      relayMi: '',
    } as any);
  }, [reset, isSubmitSuccessful]);

  return (
    <ATMGrid>
      <ATMGrid.Row>
        <ATMGrid.Column width={5}>
          <ATMField
            as={ATMSelect}
            name="substation"
            label={Lang.LBL_FORCED_OUTAGE_SUBSTATION}
            selection
            search
            control={control}
            error={errors.substation}
            clearable
            options={substationState.listForCreate.map((value) => ({
              key: value.substationId,
              value: value.substationId,
              text: value.name,
            }))}
            onChange={([_, { value }]) => value}
          />
        </ATMGrid.Column>
        <ATMGrid.Column width={4}>
          <ATMField
            name="faultMi"
            label={Lang.LBL_FORCED_OUTAGE_FAULT_MI}
            control={control}
            error={errors.faultMi}
            type="number"
            maxLength={5}
            clearable
            as={ATMInput}
            onChange={([_, { value }]) => value}
          />
        </ATMGrid.Column>
        <ATMGrid.Column width={4}>
          <ATMField
            name="relayMi"
            label={Lang.LBL_FORCED_OUTAGE_RELAY_MI}
            control={control}
            error={errors.relayMi}
            type="number"
            maxLength={5}
            as={ATMInput}
            clearable
            onChange={([_, { value }]) => value}
          />
        </ATMGrid.Column>
        <ATMGrid.Column width={3}>
          <ATMButton
            secondary
            style={{ marginTop: '19px' }}
            type="button"
            onClick={handleClick}
          >
            {Lang.LBL_FORCED_OUTAGE_ADD_ROW}
          </ATMButton>
        </ATMGrid.Column>
      </ATMGrid.Row>
    </ATMGrid>
  );
};

const ForcedOutageSubFaultRelayForm: React.FC = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const { control } = useATMFormContext<IForcedOutageForm>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'outageInformation.subFaultRelay',
  });

  const handleSubmit = useCallback(
    (data) => {
      const isExist = fields.find((val) => {
        val.substation === data.substation &&
          val.faultMi === data.faultMi &&
          val.relayMi === data.relayMi;
      });

      if (!isExist) {
        append({ ...data });
      } else {
        ToastError(Lang.MSG_FORCED_OUTAGE_DUPLICATE_ENTRY_ERROR);
      }
    },
    [append, fields]
  );

  return (
    <>
      <span className={style.iconRight}>
        <ATMIcon circular name="clipboard list" />
      </span>
      <ATMHeader
        as="h2"
        content={Lang.LBL_FORCED_OUTAGE_SUB_FAULT_RELAY}
        className={style.header}
      />

      <ATMForm
        ref={formRef}
        validationSchema={SubFaultRelayFormSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <ATMFormProvider {...props}>
            <FormContent formRef={formRef} />
          </ATMFormProvider>
        )}
      </ATMForm>

      <ATMTable>
        <ATMTable.Header>
          <ATMTable.Row>
            <ATMTable.HeaderCell />
            <ATMTable.HeaderCell>
              {Lang.LBL_FORCED_OUTAGE_SUB}
            </ATMTable.HeaderCell>
            <ATMTable.HeaderCell>
              {Lang.LBL_FORCED_OUTAGE_FAULT_MI}
            </ATMTable.HeaderCell>
            <ATMTable.HeaderCell>
              {Lang.LBL_FORCED_OUTAGE_RELAY_MI}
            </ATMTable.HeaderCell>
            <ATMTable.HeaderCell />
          </ATMTable.Row>
        </ATMTable.Header>

        <ATMTable.Body>
          {fields.length ? (
            fields.map((sub, i) => (
              <ATMTable.Row key={i}>
                <ATMTable.Cell textAlign="left">{i + 1}</ATMTable.Cell>
                <ATMTable.Cell textAlign="left">{sub.substation}</ATMTable.Cell>
                <ATMTable.Cell textAlign="left">
                  {sub.faultMi || '-'}
                </ATMTable.Cell>
                <ATMTable.Cell textAlign="left">
                  {sub.relayMi || '-'}
                </ATMTable.Cell>
                <ATMTable.Cell>
                  <ATMButton
                    icon="trash alternate outline"
                    onClick={() => remove(i)}
                    type="button"
                  />
                </ATMTable.Cell>
              </ATMTable.Row>
            ))
          ) : (
            <ATMTable.Row>
              <ATMTable.Cell colSpan={5} textAlign="center">
                {Lang.NTE_NO_DATA}
              </ATMTable.Cell>
            </ATMTable.Row>
          )}
        </ATMTable.Body>
      </ATMTable>
    </>
  );
};

export default ForcedOutageSubFaultRelayForm;
