import React, { useEffect, useState } from 'react';
import {
  ATMHeader,
  ORGDataTable,
  IORGDataTableColumn,
  IORGDataTableQueryState,
  ATMButton,
  ATMIcon,
  ATMPopover,
  ATMDropdown,
} from 'shared-it-appmod-ui';
import {
  formatDate,
  format24hTime,
  format24hDateTime,
} from 'src/libraries/moment.library';
import {
  IEmployeesRealTimeLog,
  IRealTimeLog,
  IRealTimeLogAll,
} from 'src/models/real-time-log.model';
import Lang from 'src/libraries/language';
// import moment from 'moment';
import history from 'src/history';
import {
  AccessRole,
  RealTimeInterruptionEmailQualified,
  RealTimeLogCategory,
} from 'src/constants';
import { hasRole } from 'src/libraries/access.library';
// import { getUser } from 'src/libraries/amplify.library';
import RealTimeLogEdit from '../real-time-log-edit/real-time-log-edit.component';
import RealTimeLogAll from '../real-time-log-all/real-time-log-all.component';
import style from '../real-time-log-list/real-time-log-list.module.scss';
import RealTimeLogListEmailTurnoff from '../real-time-log-list-email-turnoff/real-time-log-list-email-turnoff.component';
import RealTimeLogListEmailSend from '../real-time-log-list-email-send/real-time-log-list-email-send.component';
import { canEditRealTimeLog } from 'src/helpers/real-time-log.helper';

type IProps = {
  data: IRealTimeLog[];
  employees: IEmployeesRealTimeLog[];
  loading: boolean;
  handleFetch: (params: IORGDataTableQueryState) => void;
  total: number;
  today: number;
  currentTab: number;
  formRef?: React.RefObject<HTMLFormElement>;
  handleSubmit: (data: IRealTimeLogAll) => void;
  handleClick: () => void;
  setShowTableAll: (x: boolean) => void;
  showTableAll: boolean;
  handleDownload: () => void;
  setIsSearch: React.Dispatch<React.SetStateAction<boolean>>;
  searchFilters: Partial<IORGDataTableQueryState>;
  timer?: any;
  setPreviousTab: React.Dispatch<React.SetStateAction<number>>;
  // setUpdatedInProgressLog?: React.Dispatch<React.SetStateAction<number | null>>;
  downloadLoading: boolean;
};

const RealTimeLogListAllView: React.FC<IProps> = ({
  data,
  loading,
  handleFetch,
  total,
  handleClick,
  handleSubmit,
  showTableAll,
  setShowTableAll,
  employees,
  handleDownload,
  formRef,
  setIsSearch,
  searchFilters,
  timer,
  setPreviousTab,
  // setUpdatedInProgressLog,
  downloadLoading,
}) => {
  const [showAll, setShowAll] = useState(true);

  useEffect(() => {
    if (timer && timer.current) {
      clearTimeout(timer.current);
    }

    setPreviousTab(1);
  }, []);

  const columns: IORGDataTableColumn<IRealTimeLog>[] = [
    {
      index: 'logDtTm',
      title: Lang.LBL_DATE,
      render: (value) => {
        return (
          <>
            <div className={style.listText}>
              <b>{format24hTime(value)}</b>
              <p>{formatDate(value)}</p>
            </div>
          </>
        );
      },
      width: '10%',
    },
    {
      index: 'emp.lastName',
      title: Lang.LBL_NAME,
      width: '15%',
      render: (_, value) => {
        return (
          <>
            <div className={style.listText}>
              <b>
                {value.emp.firstName && value.emp.lastName
                  ? `${value.emp.lastName}, ${value.emp.firstName}`
                  : 'Unavailable'}
              </b>
              <p>{value.logUserType} User</p>
            </div>
          </>
        );
      },
    },
    {
      index: 'logCategory',
      title: Lang.LBL_CATEGORY,
      width: '15%',
      render: (value) =>
        value ? (
          <>
            <div className={style.listText}>
              <b>{value}</b>
            </div>
          </>
        ) : (
          '--'
        ),
    },
    {
      index: 'logEntry',
      sortable: false,
      title: Lang.LBL_DESCRIPTION,
      width: '55%',
      render: (_, value) => {
        return (
          <>
            <div>
              <b className={style.listText}>
                {value.facility && value.substation
                  ? `${value.substation?.name} - ${value.facility.volt?.voltNm}kv - ${value.facility.outgFacNm}`
                  : value.facility?.outgFacNm}
              </b>
            </div>
            <div className={style.preLine}>
              <span className={style.listText}>{value.logEntry}</span>
              {/* {value.logCategory &&
                value.logCategory !== RealTimeLogCategory.FORCEDOUTAGE && (
                  <div className={style.listText}>
                    <br />
                    <div>
                      <span>
                        <b>Occurence: </b>
                        {format24hDateTime(value.logDtTmFrcdOutOccurred)}
                      </span>
                    </div>
                    <div>
                      <span>
                        <b>Restoration: </b>
                        {value.logDtTmFrcdOutRestored
                          ? format24hDateTime(value.logDtTmFrcdOutRestored)
                          : ''}
                      </span>
                    </div>
                  </div>
                )} */}
              {value.logCategory === RealTimeLogCategory.FORCEDOUTAGE && (
                <div className={style.listText}>
                  <br />
                  <div>
                    <span>
                      <b>Occurence: </b>
                      {format24hDateTime(value.logDtTmFrcdOutOccurred)}
                    </span>
                  </div>
                  <div>
                    <span>
                      <b>Restoration: </b>
                      {value.logDtTmFrcdOutRestored
                        ? format24hDateTime(value.logDtTmFrcdOutRestored)
                        : ''}
                    </span>
                  </div>
                  <br />
                  <div>
                    <b>Subs Interrupted</b>
                  </div>
                  {value.forcedLogSubs && value.forcedLogSubs.length > 0 ? (
                    <>
                      {value.forcedLogSubs.map((val, key) => (
                        <>
                          <span key={key}>{val.substation.name}</span>
                          {value.forcedLogSubs &&
                          key < value.forcedLogSubs.length - 1 ? (
                            <span>, </span>
                          ) : (
                            ''
                          )}
                        </>
                      ))}
                    </>
                  ) : (
                    '--'
                  )}
                  <br />
                  <br />
                  <div>
                    <b>Trips and Targets</b>
                  </div>
                  {value.forcedLog && value.forcedLog.length > 0 ? (
                    <>
                      {value.forcedLog.map((val, key) => (
                        <div key={key}>
                          <i>{val.substation.name}:</i> {val.tripsTargets}
                        </div>
                      ))}
                    </>
                  ) : (
                    '--'
                  )}
                  <br />
                  <br />
                  <div className={style.changeLog}>
                    <div>
                      <b>Additional Logs</b>
                    </div>
                    {value.changeLog && value.changeLog.length > 0 ? (
                      <>
                        {value.changeLog?.map((val, key) => (
                          <div key={key}>
                            {format24hDateTime(val.updatedAt)}
                            {' | '}
                            {val.emp?.lastName
                              ? `${val.emp?.lastName}, ${val.emp?.firstName}`
                              : val.updatedBy}
                            {' | '}
                            {val.changeLogNote}
                          </div>
                        ))}
                      </>
                    ) : (
                      '--'
                    )}
                  </div>
                </div>
              )}
              {value.logCategory &&
                value.logCategory !== RealTimeLogCategory.FORCEDOUTAGE && (
                  <div className={style.listText}>
                    <br />
                    <div className={style.changeLog}>
                      <div>
                        <b>Additional Logs</b>
                      </div>
                      {value.changeLog && value.changeLog.length > 0 ? (
                        <>
                          {value.changeLog?.map((val, key) => (
                            <div key={key}>
                              {format24hDateTime(val.updatedAt)}
                              {' | '}
                              {val.emp?.lastName
                                ? `${val.emp?.lastName}, ${val.emp?.firstName}`
                                : val.updatedBy}
                              {' | '}
                              {val.changeLogNote}
                            </div>
                          ))}
                        </>
                      ) : (
                        '--'
                      )}
                    </div>
                  </div>
                )}
            </div>
          </>
        );
      },
    },
    {
      index: 'action',
      title: Lang.LBL_ACTION,
      sortable: false,
      width: '5%',
      cellProps: { textAlign: 'right' },
      render: (_, value) => {
        // const dateToday = moment();
        // const dateLog = moment(value.logDtTm);
        // const is7Days = dateToday.diff(dateLog, 'days') <= 7;
        return (
          <>
            {/* <div
              className={
                is7Days &&
                getIsAdmin(AccessRole.REAL_TIME_LOG_UPDATER) &&
                (value.logCategory === RealTimeLogCategory.FORCEDOUTAGE ||
                  (value.logCategory !== RealTimeLogCategory.FORCEDOUTAGE &&
                    getUser()?.emp_no ===
                      value.logUserId?.replace(/^00/gm, '')))
                  ? ''
                  : style.hiddenEdit
              }
            > */}
            <div
              className={
                canEditRealTimeLog(value.logCategory, value.logUserId)
                  ? ''
                  : style.hiddenEdit
              }
            >
              <RealTimeLogEdit
                data={value}
                showTableAll
                setShowTableAll={setShowTableAll}
                searchFilters={searchFilters}
              />
            </div>
            <div
              style={{ textAlign: 'center' }}
              className={
                hasRole(AccessRole.BPOR_SENDER) &&
                Object.values(RealTimeInterruptionEmailQualified).includes(
                  value.logCategory as RealTimeInterruptionEmailQualified
                ) &&
                value.frcdOutgEmailSent !== false
                  ? ''
                  : style.hiddenEdit
              }
            >
              <ATMDropdown
                pointing
                icon="mail"
                // className="link item right top"
                className={value.frcdOutgEmailSent ? style.blue : style.red}
                closeOnChange
                closeOnBlur
              >
                <ATMDropdown.Menu>
                  <RealTimeLogListEmailSend
                    data={value}
                    frcdOutgEmailSent={value.frcdOutgEmailSent}
                    // setUpdatedInProgressLog={setUpdatedInProgressLog}
                  />
                  <RealTimeLogListEmailTurnoff logId={value.logId} />
                </ATMDropdown.Menu>
              </ATMDropdown>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <div>
          <RealTimeLogAll
            handleClick={() => {
              handleClick();
              setShowAll(true);
            }}
            handleSubmit={handleSubmit}
            employees={employees}
            setShowTableAll={setShowTableAll}
            formRef={formRef}
            setIsSearch={setIsSearch}
            setShowAll={setShowAll}
            showAll={showAll}
            loading={loading}
            searchFilters={searchFilters}
          />
        </div>
        <ATMPopover
          position="bottom left"
          content="Download"
          on="hover"
          trigger={
            <ATMButton
              secondary
              icon="download"
              floated="right"
              onClick={handleDownload}
              loading={downloadLoading}
              disabled={downloadLoading || data.length === 0}
            />
          }
        />
        <br />
        <br />
        {showTableAll ? (
          <div>
            <ORGDataTable
              columns={columns}
              data={data}
              loading={loading}
              celled={false}
              sortable
              showPagination={false}
              location={history.location}
              handleLocation={history.push}
            />
            <span
              className={
                showAll && total > 50 ? style.showBtn : style.hiddenEdit
              }
            >
              <ATMButton
                type="button"
                onClick={() => {
                  handleFetch({
                    ...searchFilters,
                    limit: 0,
                  } as IORGDataTableQueryState);
                  setShowAll(!showAll);
                }}
                content="Show All Entries"
              />
            </span>
          </div>
        ) : (
          <div className={style.searchContainer}>
            <ATMIcon
              circular
              name="search"
              size="large"
              className={style.searchIcon}
            />
            <ATMHeader
              as="h3"
              content={Lang.LBL_VIEW_RECORDS}
              className={style.title}
            />
            <p className={style.subtitle}>{Lang.LBL_SEARCH_ENTRY}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default RealTimeLogListAllView;
