import React, { useCallback, useEffect, useRef } from 'react';
import { useFieldArray } from 'react-hook-form';
import { Dimmer } from 'semantic-ui-react';
import {
  ATMButton,
  ATMField,
  ATMForm,
  ATMFormProvider,
  ATMGrid,
  ATMLoader,
  ATMSelect,
  ATMTable,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import { ToastError } from 'src/components/atoms/toaster/toaster.component';
import { useFacilityContext } from 'src/contexts/facility.context';
import { useSubstationContext } from 'src/contexts/substation.context';
import { facilityActionTypes } from 'src/ducks/facility.duck';
import Lang from 'src/libraries/language';
import {
  IForcedOutageForm,
  IRestorationSubForm,
  RestorationSubFormSchema,
} from 'src/models/forced-outage.model';
import { getFacilityStatus } from 'src/selectors/facility.selector';

const FormContent: React.FC<{
  formRef?: React.RefObject<HTMLFormElement>;
  substationState: any;
  facilitySubs?: any;
}> = ({ formRef, substationState }) => {
  const {
    control,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useATMFormContext<IRestorationSubForm>();

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  useEffect(() => {
    reset({
      substationId: '',
    } as any);
  }, [reset, isSubmitSuccessful]);

  return (
    <ATMGrid columns={2}>
      <ATMGrid.Column width={5}>
        <ATMField
          name="substationId"
          label="Substation"
          as={ATMSelect}
          error={errors.substationId}
          control={control}
          options={substationState.listForCreate.map((value) => ({
            key: value.substationId,
            value: value.substationId,
            text: value.name,
          }))}
          onChange={([_, { value }]) => value}
        />
      </ATMGrid.Column>
      <ATMGrid.Column width={4} style={{ marginLeft: '0.7em' }}>
        <ATMButton
          secondary
          style={{ marginTop: '19px' }}
          type="button"
          onClick={handleClick}
        >
          {Lang.LBL_FORCED_OUTAGE_ADD_ROW}
        </ATMButton>
      </ATMGrid.Column>
    </ATMGrid>
  );
};

const ForcedOutageRestorationSubForm: React.FC<{
  isEdit?: boolean;
}> = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const { control } = useATMFormContext<IForcedOutageForm>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'restorationInformation.restorationSub',
  });
  const { state: substationState } = useSubstationContext();
  const { state: facilityState } = useFacilityContext();

  const dataStatus = getFacilityStatus(
    facilityState,
    facilityActionTypes.FACILITY_DATA_READ
  );

  // useEffect(() => {
  //   if (!isEdit) {
  //     if (facilityState.data?.substations) {
  //       setValue(
  //         'restorationInformation.restorationSub',
  //         facilityState.data.substations.map((i) => {
  //           return { substationId: i.substationId };
  //         })
  //       );
  //     }
  //   }
  // }, [facilityState]);

  const handleSubmit = useCallback(
    (data) => {
      const isExist = fields.find((val) => {
        val.substationId === data.substationId;
      });

      if (!isExist) {
        append({ ...data });
      } else {
        ToastError(Lang.MSG_FORCED_OUTAGE_DUPLICATE_ENTRY_ERROR);
      }
    },
    [append, fields]
  );

  const getSubsName = (subsId: string) => {
    const result = substationState?.listForCreate.find(
      (sub) => sub.substationId === subsId
    );
    return result?.name;
  };

  return (
    <>
      <ATMForm
        ref={formRef}
        validationSchema={RestorationSubFormSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <ATMFormProvider {...props}>
            <FormContent formRef={formRef} substationState={substationState} />
          </ATMFormProvider>
        )}
      </ATMForm>
      {/* Table */}
      <ATMGrid columns={2}>
        <ATMGrid.Column>
          <Dimmer active={dataStatus.fetching} inverted>
            <ATMLoader size="small">Loading</ATMLoader>
          </Dimmer>
          <ATMTable>
            <ATMTable.Header>
              <ATMTable.Row>
                <ATMTable.HeaderCell>
                  {Lang.LBL_SUBSTATIONS}
                </ATMTable.HeaderCell>
                <ATMTable.HeaderCell />
              </ATMTable.Row>
            </ATMTable.Header>
            <ATMTable.Body>
              {fields.length ? (
                fields.map((sub, i) => (
                  <ATMTable.Row key={sub.id}>
                    <ATMTable.Cell textAlign="left">
                      {getSubsName(sub.substationId as string)}
                    </ATMTable.Cell>
                    <ATMTable.Cell>
                      <ATMButton
                        icon="trash alternate outline"
                        onClick={() => remove(i)}
                        type="button"
                      />
                    </ATMTable.Cell>
                  </ATMTable.Row>
                ))
              ) : (
                <ATMTable.Row>
                  <ATMTable.Cell colSpan={3} textAlign="center">
                    {Lang.NTE_NO_DATA}
                  </ATMTable.Cell>
                </ATMTable.Row>
              )}
            </ATMTable.Body>
          </ATMTable>
        </ATMGrid.Column>
      </ATMGrid>
    </>
  );
};

export default ForcedOutageRestorationSubForm;
