import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  ATMButton,
  ATMDatePicker,
  ATMField,
  ATMForm,
  ATMFormProvider,
  ATMGrid,
  ATMHeader,
  ATMIcon,
  ATMMessage,
  ATMTable,
  ATMTextArea,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import {
  IForcedOutageForm,
  IPatrolForm,
  PatrolFormSchema,
} from 'src/models/forced-outage.model';
import style from './forced-outage-patrol.module.scss';
import { useFieldArray } from 'react-hook-form';
import { formatDate } from 'src/libraries/moment.library';
import { ToastError } from 'src/components/atoms/toaster/toaster.component';
import moment from 'moment';
import { isEqual } from 'date-fns';

const FormContent: React.FC<{
  formRef?: React.RefObject<HTMLFormElement>;
  data: IForcedOutageForm;
}> = ({ formRef, data }) => {
  const {
    control,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useATMFormContext<IPatrolForm>();

  const [patrolDt, setPatrolDt] = useState<Date | null>();

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  useEffect(() => {
    reset({
      patrolDt: '',
      comment: '',
    } as any);
  }, [reset, isSubmitSuccessful]);

  return (
    <>
      <ATMGrid>
        <ATMGrid.Row columns={2}>
          <ATMGrid.Column>
            <ATMField
              name="patrolDt"
              label={Lang.LBL_FORCED_OUTAGE_PATROL_DATE}
              as={ATMDatePicker}
              control={control}
              format="MM/DD/YYYY"
              minDate={data.startDate}
              disabled={!data.startDate}
              dateTimePlaceHolder="Select"
              placeholder={Lang.LBL_SELECT}
              error={errors.patrolDt}
              onChange={([_, { value: val }]) => {
                if (!val) {
                  setPatrolDt(null);
                }
                setPatrolDt(val);
                return val;
              }}
              clearable
            />
          </ATMGrid.Column>
        </ATMGrid.Row>
      </ATMGrid>
      <ATMGrid>
        <ATMGrid.Row>
          <ATMGrid.Column width={13}>
            <div
              className={
                !data.startDate ? style.disabledTextArea : style.commentTextArea
              }
            >
              <ATMField
                name="comment"
                label={Lang.LBL_FORCED_OUTAGE_COMMENT}
                error={errors.comment}
                as={ATMTextArea}
                control={control}
                disabled={!data.startDate}
                onChange={([_, { value }]) => value}
              />
            </div>
          </ATMGrid.Column>
          <ATMGrid.Column width={3}>
            <div className={style.buttonPosition}>
              <ATMButton
                secondary
                type="button"
                disabled={!data.startDate || !patrolDt}
                onClick={handleClick}
              >
                {Lang.LBL_FORCED_OUTAGE_ADD_ROW}
              </ATMButton>
            </div>
          </ATMGrid.Column>
        </ATMGrid.Row>
      </ATMGrid>
    </>
  );
};

const ForcedOutagePatrolForm: React.FC = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const { control, getValues } = useATMFormContext<IForcedOutageForm>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'outageInformation.patrol',
  });

  const handleSubmit = useCallback(
    (data) => {
      const isExist = fields.find((val) => {
        return isEqual(
          moment(val.patrolDt, 'YYYYMMDD').toDate(),
          moment(data.patrolDt, 'YYYYMMDD').toDate()
        );
      });

      if (!isExist) {
        append({ ...data });
      } else {
        ToastError(Lang.MSG_FORCED_OUTAGE_DUPLICATE_ENTRY_ERROR);
      }
    },
    [append, fields]
  );

  return (
    <>
      <span className={style.iconRight}>
        <ATMIcon circular name="clipboard list" />
      </span>
      <ATMHeader
        as="h2"
        content={Lang.LBL_FORCED_OUTAGE_PATROL_INFORMATION}
        className={style.header}
      />
      {!getValues().startDate ? (
        <ATMMessage color="yellow" size="tiny" style={{ width: 500 }}>
          Notice: Occurence Date is required to add Patrol Information.
        </ATMMessage>
      ) : null}

      <ATMForm
        ref={formRef}
        validationSchema={PatrolFormSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <ATMFormProvider {...props}>
            <FormContent formRef={formRef} data={getValues()} />
          </ATMFormProvider>
        )}
      </ATMForm>

      <ATMTable>
        <ATMTable.Header>
          <ATMTable.Row>
            <ATMTable.HeaderCell />
            <ATMTable.HeaderCell>
              {Lang.LBL_FORCED_OUTAGE_PATROL_DATE}
            </ATMTable.HeaderCell>
            <ATMTable.HeaderCell>
              {Lang.LBL_FORCED_OUTAGE_COMMENT}
            </ATMTable.HeaderCell>
            <ATMTable.HeaderCell />
          </ATMTable.Row>
        </ATMTable.Header>

        <ATMTable.Body>
          {fields.length ? (
            fields.map((patrol, i) => (
              <ATMTable.Row key={patrol.id}>
                <ATMTable.Cell>{i + 1}</ATMTable.Cell>
                <ATMTable.Cell>
                  {patrol.patrolDt ? formatDate(patrol.patrolDt) : ''}
                </ATMTable.Cell>
                <ATMTable.Cell>
                  {patrol.comment ? patrol.comment : ''}
                </ATMTable.Cell>
                <ATMTable.Cell>
                  <ATMButton
                    icon="trash alternate outline"
                    onClick={() => remove(i)}
                    type="button"
                  />
                </ATMTable.Cell>
              </ATMTable.Row>
            ))
          ) : (
            <ATMTable.Row>
              <ATMTable.Cell colSpan={4} textAlign="center">
                {Lang.NTE_NO_DATA}
              </ATMTable.Cell>
            </ATMTable.Row>
          )}
        </ATMTable.Body>
      </ATMTable>
    </>
  );
};

export default ForcedOutagePatrolForm;
