import { ForcedOutageFacilityType } from 'src/constants/forced-outage.constants';
import Lang from 'src/libraries/language';
import yup from 'src/libraries/validator.library';
import { FacilityLogShape } from './real-time-log.model';
import { FileSchema } from './file.model';
import { VoltShape } from './volt.model';

export const OutageSharedInfoShape = {
  id: yup.number().required(),
  outageId: yup.string().required(),
  logId: yup.number().nullable(),
  status: yup.number().required(),
  eventYr: yup.number().required(),
  description: yup.string(),
  outageClassId: yup.number().nullable(),
  substationId: yup.string().nullable(),
  equipType: yup.string().required(),
  tlId: yup.number().nullable(),
  equipId: yup.number().required(),
  causeCd: yup.number().nullable(),
  suppCauseCd: yup.number().nullable(),
  startDate: yup.date().nullable(),
  startTm: yup.string().nullable(),
  endDate: yup.date().nullable(),
  endTm: yup.string().nullable(),
  duration: yup.number().nullable(),
  voltage: yup.string().required(),
  createdAt: yup.date().nullable(),
  createdBy: yup.string().nullable(),
  updatedAt: yup.date().nullable(),
  updatedBy: yup.string().nullable(),
};

export const OutageSharedInfoCreateShape = {
  ...OutageSharedInfoShape,
  id: yup.number(),
  description: yup.string().nullable(),
  eventYr: yup.number(),
  equipType: yup.string(),
  equipId: yup.number(),
  duration: yup.number(),
  voltage: yup.string().nullable(),
};

export const OutageSharedInfoPayloadShape = {
  ...OutageSharedInfoShape,
  id: yup.number().nullable(),
  createdAt: yup.date().nullable(),
  createdBy: yup.string().nullable(),
  updatedAt: yup.date().nullable(),
  updatedBy: yup.string().nullable(),
};

export const ForcedOutageListSearchSchema = yup.object({
  facilityName: yup.string().nullable(),
  outageId: yup.string().nullable(),
  facilityType: yup.string().nullable(),
  dateRange: yup.array().of(yup.date()),
  substationId: yup.string().nullable(),
  outgFacId: yup.string().nullable(),
  status: yup.array().of(yup.number()).nullable(),
});

export const ForcedOutageOverviewShape = {
  equipType: yup.string().required(), //.typeError(Lang.MSG_ERROR_REQUIRED),
  equipId: yup.number().required(), //.typeError(Lang.MSG_ERROR_REQUIRED),
  tlId: yup.number().required(),
  voltage: yup.string().required(),
  status: yup.number().nullable(),
  substationId: yup.string().nullable(),
  outageClassId: yup.number().nullable(),
  startDate: yup.date().nullable(),
  startTm: yup.string().nullable(),
  endDate: yup.date().nullable(),
  causeCd: yup.number().nullable(),
  suppCauseCd: yup.number().nullable(),
  endTm: yup.string().nullable(),
  description: yup.string().nullable(),
};

export const ForcedOutageOverviewSchema = yup.object(ForcedOutageOverviewShape);

export const SubFaultRelayFormSchema = yup.object({
  faultId: yup.number().nullable(),
  id: yup.number().nullable(),
  substation: yup.string().nullable(),
  faultMi: yup.number().nullable(),
  relayMi: yup.number().nullable(),
  updatedAt: yup.date().nullable(),
  updatedBy: yup.string().nullable(),
});

export const PatrolFormSchema = yup.object({
  patrolId: yup.number().nullable(),
  patrolDt: yup.date().nullable(),
  comment: yup.string().nullable(),
});

export const GOSValidateForm = yup.object({
  loadRestoreDt: yup.date().required(),
  loadDroppped: yup.boolean().required(),
  loadDropDuration: yup.number().required(),
  substationDropped: yup.string().required(),
  initiatingRowItem: yup.boolean().nullable(),
  restorationSub: yup.array(
    yup.object({
      substationId: yup.number().required(),
    })
  ),
});

export const ForcedOutageOverviewForm = yup.object({
  facilityTypeId: yup.number().required().typeError(Lang.MSG_ERROR_REQUIRED), // 1Line/2Subs
  substationId: yup
    .string()
    .nullable()
    .when('facilityTypeId', {
      is: (val) => val === ForcedOutageFacilityType.Station,
      then: (schema) => schema.required(),
    }),
  equipType: yup.string().typeError(Lang.MSG_ERROR_REQUIRED),
  equipId: yup.number().typeError(Lang.MSG_ERROR_REQUIRED),
  tlId: yup.string().nullable(),
  voltage: yup.string().nullable(),
  startDate: yup.date().nullable(),
  startTm: yup.string().nullable(),
  endDate: yup.date().nullable(),
  endTm: yup.string().nullable(),
  duration: yup.number().nullable(),
  causeCd: yup.number().nullable(),
  suppCauseCd: yup.number().nullable(),
  description: yup.string().nullable(),
});

export const OutageCommentShape = {
  commentId: yup.number().nullable(),
  id: yup.number().nullable(),
  comment: yup.string().nullable(),
  archive: yup.boolean().nullable(),
  updatedAt: yup.date().nullable(),
  updatedBy: yup.string().nullable(),
};

export const OutageInfoShape = {
  outageInfoId: yup.number().nullable(),
  id: yup.number().nullable(),
  strNum: yup.string().nullable(),
  mileage: yup.number().nullable(),
  lineTripped: yup.boolean().nullable(),
  faultDistance: yup.boolean().nullable(),
  piNotification: yup.boolean().nullable(),
  wireDown: yup.boolean().nullable(),
  ohUg: yup.string().nullable(),
  updatedAt: yup.date().nullable(),
  updatedBy: yup.string().nullable(),
};

export const SubFaultRelayShape = {
  faultId: yup.number().nullable(),
  id: yup.number().nullable(),
  substation: yup.string().nullable(),
  faultMi: yup.number().nullable(),
  relayMi: yup.number().nullable(),
  updatedAt: yup.date().nullable(),
  updatedBy: yup.string().nullable(),
};

export const PatrolShape = {
  patrolId: yup.number().nullable(),
  id: yup.number().nullable(),
  patrolDt: yup.date().nullable(),
  comment: yup.string().nullable(),
  updatedAt: yup.date().nullable(),
  updatedBy: yup.string().nullable(),
};

export const RestorationSubShape = {
  restorationSubId: yup.number().nullable(),
  id: yup.number().nullable(),
  substationId: yup.string().nullable(),
  updatedAt: yup.date().nullable(),
  updatedBy: yup.string().nullable(),
};

export const RestorationInfoShape = {
  restorationId: yup.number().nullable(),
  id: yup.number().nullable(),
  loadRestoreDt: yup.date().nullable(),
  loadDroppped: yup.boolean().nullable(),
  loadDropDuration: yup.number().nullable(),
  substationDropped: yup.string().nullable(),
  initiatingRowItem: yup.boolean().nullable(),
  updatedAt: yup.date().nullable(),
  updatedBy: yup.string().nullable(),
};

export const CauseCodeShape = {
  causeCd: yup.number().nullable(),
  description: yup.string().nullable(),
};

export const UpdatedByEmployeeShape = {
  empId: yup.string().nullable(),
  firstName: yup.string().nullable(),
  fullName: yup.string().nullable(),
  lastName: yup.string().nullable(),
  middleName: yup.string().nullable(),
};

export const OutageCommentsSchema = yup.object({
  ...OutageCommentShape,
  updatedByEmployee: yup.object(UpdatedByEmployeeShape),
});

export const PatrolFormShape = yup.object(PatrolShape);

export const ForcedOutageInfoSchema = yup.object({
  strNum: yup.string().nullable(),
  mileage: yup.number().nullable(),
  lineTripped: yup.boolean().nullable(),
  faultDistance: yup.boolean().nullable(),
  piNotification: yup.boolean().nullable(),
  wireDown: yup.boolean().nullable(),
  ohUg: yup.string().nullable(),
  subFaultRelay: yup
    .array()
    .of(
      yup.object({
        substation: yup.string().nullable(),
        faultMi: yup.number().nullable(),
        relayMi: yup.number().nullable(),
      })
    )
    .nullable(),
  patrol: yup
    .array()
    .of(
      yup.object({
        patrolDt: yup.date().nullable(),
        comment: yup.string().nullable(),
      })
    )
    .nullable(),
});

export const ForcedOutageRestorationSchema = yup.object({
  loadRestoreDt: yup.date().nullable(),
  loadDroppped: yup.boolean().nullable(),
  loadDropDuration: yup.number().nullable(),
  substationDropped: yup.string().nullable(),
  initiatingRowItem: yup.boolean().nullable(),
  interval: yup.number().nullable(),
  hftdClass: yup.string().nullable(),
  outageClass: yup.string().nullable(),
  restorationSub: yup
    .array()
    .of(
      yup.object({
        substationId: yup.string().nullable(),
      })
    )
    .nullable(),
});

export const ForcedOutageShape = {
  id: yup.number().nullable(),
  outageId: yup.string().nullable(),
  logId: yup.number().nullable(),
  eventYr: yup.number().nullable(),
  description: yup.string().nullable(),
  equipType: yup.string().nullable(),
  tlId: yup.number().nullable(),
  equipId: yup.string().nullable(),
  causeCd: yup.number().nullable(),
  suppCauseCd: yup.number().nullable(),
  startDate: yup.date().nullable(),
  startTm: yup.string().nullable(),
  endDate: yup.date().nullable(),
  endTm: yup.string().nullable(),
  duration: yup.number().nullable(),
  voltage: yup.string().nullable(),
  status: yup.number().nullable(),
  facilityTypeId: yup.number().nullable(),
  documents: yup.array().of(FileSchema),
  outgFacId: yup.number().nullable(),
  facility: yup.object(FacilityLogShape).nullable(),
  outageComments: yup.array().of(yup.object(OutageCommentShape)).nullable(),
  outageInfo: yup.object(OutageInfoShape).nullable(),
  subFaultRelay: yup
    .array()
    .of(yup.object(SubFaultRelayShape).nullable())
    .nullable(),
  patrol: yup.array().of(yup.object(PatrolShape).nullable()).nullable(),
  restorationSub: yup.object(RestorationSubShape).nullable(),
  restorationInfo: yup.object(RestorationInfoShape).nullable(),
  causeCode: yup.object(CauseCodeShape).nullable(),
  suppCauseCode: yup.object(CauseCodeShape).nullable(),
};

export const ForcedOutagePayloadSchema = yup.object({
  id: yup.number().nullable(),
  outageId: yup.string().nullable(),
  logId: yup.number().nullable(),
  eventYr: yup.number().nullable(),
  description: yup.string().nullable(),
  equipType: yup.string().nullable(),
  tlId: yup.number().nullable(),
  outgFacId: yup.number().nullable(),
  equipId: yup.string().nullable(),
  causeCd: yup.number().nullable(),
  suppCauseCd: yup.number().nullable(),
  substationId: yup.string().nullable(),
  startDate: yup.date().nullable(),
  startTm: yup.string().nullable(),
  endDate: yup.date().nullable(),
  endTm: yup.string().nullable(),
  duration: yup.number().nullable(),
  voltage: yup.string().nullable(),
  status: yup.number().nullable(),
  facilityTypeId: yup.number().nullable(),
  causeCode: yup.object(CauseCodeShape).nullable(),
  suppCauseCode: yup.object(CauseCodeShape).nullable(),
  facility: yup.object(FacilityLogShape).nullable(),
  outageComments: yup.array().of(OutageCommentsSchema).nullable(),
  outageInformation: yup.object({
    strNum: yup.string().nullable(),
    mileage: yup.number().nullable(),
    lineTripped: yup.boolean().nullable(),
    faultDistance: yup.boolean().nullable(),
    piNotification: yup.boolean().nullable(),
    wireDown: yup.boolean().nullable(),
    ohUg: yup.string().nullable(),
    subFaultRelay: yup.array().of(SubFaultRelayFormSchema).nullable(),
    patrol: yup.array().of(yup.object(PatrolShape)).nullable(),
  }),
  restorationInformation: yup.object({
    loadRestoreDt: yup.date().nullable(),
    loadDroppped: yup.boolean().nullable(),
    loadDropDuration: yup.number().nullable(),
    substationDropped: yup.string().nullable(),
    initiatingRowItem: yup.boolean().nullable(),
    hftd: yup.string().nullable(),
    restorationSub: yup
      .array()
      .of(
        yup.object({
          substationId: yup.string().nullable(),
          substation: yup.object({
            substationId: yup.string().nullable(),
            name: yup.string().nullable(),
          }),
        })
      )
      .nullable(),
  }),
});

export const RestorationSubFormSchema = yup.object({
  substationId: yup.string().nullable(),
});

export const OutageCommentFormSchema = yup.object({
  comment: yup.string(),
  archive: yup.boolean().nullable(),
  updatedAt: yup.date(),
  updatedBy: yup.string(),
});

export const ForcedOutageFormSchema = yup.object({
  step: yup.string(),
  // overview
  facilityTypeId: yup.number().required(),
  substationId: yup
    .string()
    .nullable()
    .when('facilityTypeId', {
      is: (val) => val === ForcedOutageFacilityType.Station,
      then: (schema) => schema.required(),
    }),
  equipType: yup.string().nullable(),
  equipId: yup.string().nullable(),
  tlId: yup.string().nullable(),
  voltage: yup.string().nullable(),
  startDate: yup.date().required(),
  startTm: yup.string().required(),
  outgFacId: yup.number().nullable(),
  endDate: yup.date().nullable(),
  endTm: yup.string().nullable(),
  causeCd: yup.number().nullable(),
  suppCauseCd: yup.number().nullable(),
  description: yup.string().nullable(),
  facility: yup
    .object({
      ...FacilityLogShape,
      outgFacId: yup.number().required(),
      outgFacNm: yup.string().required(),
      volt: yup.object({
        ...VoltShape,
        voltId: yup.number().nullable(),
        voltNm: yup.string().nullable(),
      }),
    })
    .nullable(),
  status: yup.number(),
  outageComments: yup.array().of(OutageCommentFormSchema).nullable(),
  outageInformation: yup.object({
    strNum: yup.string().nullable(),
    mileage: yup.number().nullable(),
    lineTripped: yup.boolean().nullable(),
    faultDistance: yup.boolean().nullable(),
    piNotification: yup.boolean().nullable(),
    wireDown: yup.boolean().nullable(),
    ohUg: yup.string().nullable(),
    subFaultRelay: yup.array().of(SubFaultRelayFormSchema).nullable(),
    patrol: yup.array().of(PatrolFormSchema).nullable(),
  }),
  restorationInformation: yup.object({
    loadRestoreDt: yup.date().nullable(),
    loadDroppped: yup.boolean().nullable(),
    loadDropDuration: yup.number().nullable(),
    substationDropped: yup.string().nullable(),
    initiatingRowItem: yup.boolean().nullable(),
    interval: yup.number().nullable(),
    hftdClass: yup.string().nullable(),
    outageClass: yup.string().nullable(),
    restorationSub: yup.array().of(RestorationSubFormSchema).nullable(),
  }),
});

export const ForceOutageFormDraftSchema = yup.object({
  step: yup.string(),
  id: yup.number().nullable(),
  facilityTypeId: yup.number().nullable(), // 1Line/2Subs
  substationId: yup
    .string()
    .nullable()
    .when('facilityTypeId', {
      is: (val) => val === ForcedOutageFacilityType.Station,
      then: (schema) => schema.required(),
    }),
  equipType: yup.string().nullable(),
  equipId: yup.string().nullable(),
  tlId: yup.number().nullable(),
  voltage: yup.string().nullable(),
  startDate: yup.date().nullable(),
  startTm: yup.string().nullable(),
  endDate: yup.date().nullable(),
  endTm: yup.string().nullable(),
  causeCd: yup.number().nullable(),
  suppCauseCd: yup.number().nullable(),
  description: yup.string().nullable(),
  status: yup.number().nullable(),
  outgFacId: yup.number().nullable(),
  facility: yup
    .object({
      ...FacilityLogShape,
      outgFacId: yup.number().nullable(),
      outgFacNm: yup.string().nullable(),
      volt: yup.object({
        ...VoltShape,
        voltId: yup.number().nullable(),
        voltNm: yup.string().nullable(),
      }),
    })
    .nullable(),
  outageComments: yup.array().of(OutageCommentFormSchema).nullable(),
  outageInformation: yup.object({
    strNum: yup.string().nullable(),
    mileage: yup.number().nullable(),
    lineTripped: yup.boolean().nullable(),
    faultDistance: yup.boolean().nullable(),
    piNotification: yup.boolean().nullable(),
    wireDown: yup.boolean().nullable(),
    ohUg: yup.string().nullable(),
    subFaultRelay: yup.array().of(SubFaultRelayFormSchema).nullable(),
    patrol: yup.array().of(PatrolFormSchema).nullable(),
  }),
  restorationInformation: yup.object({
    loadRestoreDt: yup.date().nullable(),
    loadDroppped: yup.boolean().nullable(),
    loadDropDuration: yup.number().nullable(),
    substationDropped: yup.string().nullable(),
    initiatingRowItem: yup.boolean().nullable(),
    interval: yup.number().nullable(),
    hftdClass: yup.string().nullable(),
    outageClass: yup.string().nullable(),
    restorationSub: yup.array().of(RestorationSubFormSchema).nullable(),
  }),
});
export const CauseCodeSchema = yup.object(CauseCodeShape);

export const OutageSharedInfoCreateSchema = yup.object(
  OutageSharedInfoCreateShape
);

export const OutageSharedInfoPayloadSchema = yup.object(
  OutageSharedInfoPayloadShape
);

export const ForcedOutageSchema = yup.object(ForcedOutageShape);

export const ForcedOutageListPayloadSchema = yup.object({
  count: yup.number().nullable(),
  rows: yup.array().of(ForcedOutageSchema.clone()),
});

export const OutageCommentSchema = yup.object(OutageCommentShape);

export type IForcedOutageListPayload = yup.Asserts<
  typeof ForcedOutageListPayloadSchema
>;

export type IForcedOutageListSearch = yup.Asserts<
  typeof ForcedOutageListSearchSchema
>;

export type IOutageSharedInfoForm = yup.Asserts<
  typeof OutageSharedInfoCreateSchema
>;

export type IOutageSharedInfoPayload = yup.Asserts<
  typeof OutageSharedInfoPayloadSchema
>;

export type IForcedOutageForm = yup.Asserts<typeof ForcedOutageFormSchema>;

export type IForcedOutage = yup.Asserts<typeof ForcedOutageSchema>;
export type IForcedOutageOverview = yup.Asserts<
  typeof ForcedOutageOverviewSchema
>;

export type ISubFaultRelayForm = yup.Asserts<typeof SubFaultRelayFormSchema>;

export type IForcedOutagePayload = yup.Asserts<
  typeof ForcedOutagePayloadSchema
>;

export type IOutageComments = yup.Asserts<typeof OutageCommentsSchema>;

export type IPatrolForm = yup.Asserts<typeof PatrolFormSchema>;

export type ICauseCode = yup.Asserts<typeof CauseCodeSchema>;

export type IForcedOutageRestorationSubForm = yup.Asserts<
  typeof ForcedOutageRestorationSchema
>;

export type IForceOutageFormDraft = yup.Asserts<
  typeof ForceOutageFormDraftSchema
>;

export type IRestorationSubForm = yup.Asserts<typeof RestorationSubFormSchema>;

export type IOutageCommentForm = yup.Asserts<typeof OutageCommentFormSchema>;
