import React from 'react';
import {
  ATMField,
  ATMGrid,
  ATMHeader,
  ATMIcon,
  ATMInput,
  ATMSelect,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { LERRequestWidth } from 'src/constants';
import style from './forced-outage-information-form.module.scss';
import {
  IForcedOutage,
  IForcedOutageForm,
} from 'src/models/forced-outage.model';
import ForcedOutagePanel from '../foced-outage-panel/forced-outage-panel.component';
import ForcedOutagePatrolForm from '../forced-outage-patrol/forced-outage-patrol-form.component';
import ForcedOutageSubFaultRelayForm from '../forced-outage-sub-fault-relay/forced-outage-sub-fault-relay-form.component';

export type IProps = {
  header?: React.ReactNode;
  defaultValues?: IForcedOutage;
  data?: IForcedOutage;
  loading?: boolean;
  draftId?: number;
  isSubmitted?: boolean;
  isUpdated?: boolean;
  handleClose?: () => void;
  handleSave?: (data: Partial<IForcedOutageForm>) => Promise<void>;
  handleSubmit?: (data: IForcedOutageForm) => void;
  setFileCached?: (data: boolean) => void;
  isClone?: boolean;
  isEdit?: boolean;
};

type IPropFormContent = {
  data?: Partial<IForcedOutage | IForcedOutageForm>;
};

const FormContent: React.FC<IPropFormContent> = () => {
  const {
    control,
    formState: { errors },
  } = useATMFormContext<IForcedOutageForm>();
  const dropdownOptions = [
    { key: 1, value: true, text: 'True' },
    { key: 2, value: false, text: 'False' },
  ];
  return (
    <>
      <span className={style.iconRight}>
        <ATMIcon circular name="clipboard list" />
      </span>
      <ATMHeader
        as="h2"
        content={Lang.TTL_FORCED_OUTAGE_INFORMATION}
        className={style.header}
      />
      <ATMGrid columns={3} style={{ maxWidth: 1023 }}>
        <ATMGrid.Column>
          <ATMField
            name="outageInformation.strNum"
            as={ATMInput}
            label={Lang.LBL_FORCED_OUTAGE_STRNUM}
            control={control}
            error={errors.outageInformation?.strNum}
            type="number"
            maxLength={5}
            clearable
          />
        </ATMGrid.Column>
        <ATMGrid.Column>
          <ATMField
            name="outageInformation.mileage"
            as={ATMInput}
            label={Lang.LBL_FORCED_OUTAGE_TL_MILEAGE}
            control={control}
            error={errors.outageInformation?.mileage}
            type="number"
            maxLength={5}
            clearable
            onChange={(_, { value }) => {
              if (!value) {
                return null;
              }
              return value;
            }}
          />
        </ATMGrid.Column>
        <ATMGrid.Column>
          <ATMField
            name="outageInformation.lineTripped"
            label={Lang.LBL_FORCED_OUTAGE_LINE_TRIPPED}
            as={ATMSelect}
            selection
            error={errors.outageInformation?.lineTripped}
            selectOnBlur={false}
            control={control}
            clearable
            options={dropdownOptions}
            onChange={([_, { value }]) => value}
          />
        </ATMGrid.Column>
        <ATMGrid.Column>
          <ATMField
            name="outageInformation.faultDistance"
            label={Lang.LBL_FORCED_OUTAGE_FAULT_DISTANCE}
            as={ATMSelect}
            error={errors.outageInformation?.faultDistance}
            control={control}
            clearable
            options={dropdownOptions}
            selectOnBlur={false}
            onChange={([_, { value }]) => value}
          />
        </ATMGrid.Column>
        <ATMGrid.Column>
          <ATMField
            name="outageInformation.piNotification"
            label={Lang.LBL_FORCED_OUTAGE_PI_NOTIFICATION}
            as={ATMSelect}
            error={errors.outageInformation?.piNotification}
            control={control}
            clearable
            options={dropdownOptions}
            selectOnBlur={false}
            onChange={([_, { value }]) => value}
          />
        </ATMGrid.Column>
        <ATMGrid.Column>
          <ATMField
            name="outageInformation.wireDown"
            label={Lang.LBL_FORCED_OUTAGE_WIRE_DOWN}
            as={ATMSelect}
            error={errors.outageInformation?.wireDown}
            control={control}
            clearable
            options={dropdownOptions}
            selectOnBlur={false}
            onChange={([_, { value }]) => value}
          />
        </ATMGrid.Column>
        <ATMGrid.Column>
          <ATMField
            name="outageInformation.ohug"
            label={Lang.LBL_FORCED_OUTAGE_OH_UG}
            as={ATMSelect}
            selection
            clearable
            control={control}
            options={[
              { key: 1, value: 'overhead', text: 'Overhead' },
              { key: 2, value: 'underground', text: 'Underground' },
            ]}
            selectOnBlur={false}
            onChange={([_, { value }]) => value}
          />
        </ATMGrid.Column>

        <ATMGrid.Column width={16}>
          <ForcedOutageSubFaultRelayForm />
        </ATMGrid.Column>
        <ATMGrid.Column width={16}>
          <ForcedOutagePatrolForm />
        </ATMGrid.Column>
      </ATMGrid>
    </>
  );
};

const ForcedOutageInformationForm: React.FC<IProps> = ({
  setFileCached,
  data,
  isEdit,
  isUpdated,
}) => {
  return (
    <ATMGrid divided>
      <ATMGrid.Column width={LERRequestWidth.LEFT}>
        <FormContent />
      </ATMGrid.Column>
      <ATMGrid.Column width={LERRequestWidth.RIGHT}>
        <ForcedOutagePanel
          isEdit={isEdit}
          data={data}
          setFileCached={setFileCached}
          isUpdated={isUpdated}
        />
      </ATMGrid.Column>
    </ATMGrid>
  );
};

export default ForcedOutageInformationForm;
