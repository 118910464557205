import React, { useCallback, useRef, useState } from 'react';
import { Confirm } from 'semantic-ui-react';
import {
  ATMButton,
  ATMField,
  ATMForm,
  ATMModal,
  ATMTextArea,
} from 'shared-it-appmod-ui';
import Access from 'src/components/atoms/access/access.component';
import { AccessRole, AccessType } from 'src/constants';
import { ForcedOutageStatus } from 'src/constants/forced-outage.constants';
import { useForcedOutageContext } from 'src/contexts/forced-outage.context';
import { forcedOutageListActionTypes } from 'src/ducks/forced-outage.duck';
import { hasRole } from 'src/libraries/access.library';
import Lang from 'src/libraries/language';
import { IForcedOutagePayload } from 'src/models/forced-outage.model';
import { LerRequestRejectFormSchema } from 'src/models/ler-request.model';
import { getForcedOutageStatus } from 'src/selectors/forced-outage.selector';

type IProps = {
  id: number;
  data?: IForcedOutagePayload;
  trigger?: React.ReactNode;
  isOptionTwo?: boolean;
};

const ForcedOutageReject: React.FC<IProps> = ({
  id,
  data,
  trigger,
  isOptionTwo = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const temporarilyDisable = true;
  const { state, actions } = useForcedOutageContext();

  const status = getForcedOutageStatus(
    state,
    forcedOutageListActionTypes.FORCED_OUTAGE_DATA_REJECT
  );
  const [isConfirm, setIsConfirm] = useState(false);
  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleReject();
    }
  }, [formRef]);
  const handleReject = useCallback(async () => {
    await actions.rejectPUT(id);
  }, [actions]);

  if (isOptionTwo) {
    return (
      <Access
        type={AccessType.FORCED_OUTAGE_GOS}
        when={
          hasRole(AccessRole.FORCED_OUTAGE_RESOLVER) &&
          [ForcedOutageStatus.UnderReviewGOS].includes(
            data?.status as ForcedOutageStatus
          )
        }
      >
        <ATMModal
          open={isOpen}
          size="tiny"
          onClose={() => setIsOpen(false)}
          onOpen={() => setIsOpen(true)}
          trigger={
            trigger || (
              <ATMButton
                negative
                type="button"
                content={Lang.LBL_FORCED_OUTAGE_REJECT}
                disabled={temporarilyDisable || status.fetching}
                loading={status.fetching}
              />
            )
          }
        >
          <ATMModal.Header>
            {`${Lang.LBL_FORCED_OUTAGE_REJECT} Forced Outage`}
          </ATMModal.Header>
          <ATMModal.Content>
            <p>{Lang.MSG_FORCED_OUTAGE_CONFIRM_REJECT}</p>

            <ATMForm
              ref={formRef}
              onSubmit={handleReject}
              mode="onChange"
              validationSchema={LerRequestRejectFormSchema}
            >
              {({ control, formState: { errors } }) => (
                <>
                  <ATMField
                    name="rejectionReason"
                    label={Lang.LBL_REASON_OF_REJECTION}
                    control={control}
                    as={ATMTextArea}
                    error={errors.rejectionReason}
                    charLimit={1000}
                    charCount
                  />
                </>
              )}
            </ATMForm>
          </ATMModal.Content>
          <ATMModal.Actions>
            <ATMButton
              secondary
              content={Lang.LBL_CANCEL}
              onClick={() => setIsOpen(false)}
              type="button"
            />
            <ATMButton
              type="button"
              negative
              content={Lang.LBL_FORCED_OUTAGE_REJECT}
              onClick={handleClick}
              disabled={status.fetching}
              loading={status.fetching}
            />
          </ATMModal.Actions>
        </ATMModal>
      </Access>
    );
  }
  return (
    <>
      <Access
        type={AccessType.FORCED_OUTAGE_GOS}
        when={
          hasRole(AccessRole.FORCED_OUTAGE_RESOLVER) &&
          [ForcedOutageStatus.UnderReviewGOS].includes(
            data?.status as ForcedOutageStatus
          )
        }
      >
        <>
          <Confirm
            open={isConfirm}
            size="small"
            onConfirm={handleReject}
            onOpen={() => setIsConfirm(true)}
            onCancel={() => setIsConfirm(false)}
            trigger={
              <ATMButton
                negative
                type="button"
                content={Lang.LBL_FORCED_OUTAGE_REJECT}
                disabled={status.fetching}
                loading={status.fetching}
              />
            }
            header={`${Lang.LBL_FORCED_OUTAGE_REJECT} Forced Outage`}
            content={Lang.MSG_FORCED_OUTAGE_CONFIRM_REJECT}
            loading={status.fetching}
          />
        </>
      </Access>
    </>
  );
};

export default ForcedOutageReject;
