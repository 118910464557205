export enum AccessRole {
  MCC_OUTAGE_SCHEDULER = 1,
  MCC_OUTAGE_REQUESTOR = 2,
  MCC_OPERATIONAL_ENGINEER = 3,
  MCC_REAL_TIME_OPERATOR = 4,
  ROLES_ADMINISTRATOR = 5,
  GCC_KEY_ADMINISTRATOR = 6,
  GCC_KEY_REQUESTOR = 7,
  GCC_SUBENTRY_LOG_UPDATE = 8,
  GCC_SUBENTRY_LOG_VIEW = 9,
  GCC_AUTH_LIST_ADMIN = 10,
  REAL_TIME_LOG_VIEWER = 11,
  REAL_TIME_LOG_UPDATER = 12,
  MCC_OUTAGE_VIEWER = 13,
  TROUBLE_TICKET_REQUESTOR = 14,
  TROUBLE_TICKET_RESOLVER = 15,
  TROUBLE_TICKET_VIEWER = 16,
  MCC_SUBMITTING_SUPERVISOR = 17,
  TROUBLE_JOB_VIEWER = 18,
  TROUBLE_JOB_UPDATER = 19,
  GCC_AUTH_LIST_VIEWER = 20,
  MCC_LER_APPROVERS = 21,
  BPOR_SENDER = 22,
  BPOR_VIEWER = 23,
  CALENDAR_VIEWER = 24,
  FORCED_OUTAGE_REQUESTOR = 25,
  FORCED_OUTAGE_RESOLVER = 26,
  FORCED_OUTAGE_VIEWER = 27,
}

export enum AccessType {
  AUTH_LIST,
  ROLES,
  KEYS,
  SUBSTATION_ENTRY_LOG_UPDATE,
  SUBSTATION_ENTRY_LOG_READ,
  OUTAGE_REQUEST_READ,
  OUTAGE_REQUEST_WITHDRAW,
  OUTAGE_REQUEST_AUTH_STATEMENT,
  OUTAGE_REQUEST_ISO,
  OUTAGE_REQUEST_FACILITY,
  OUTAGE_REQUEST_DATE,
  OUTAGE_REQUEST_JOB_INFORMATION,
  OUTAGE_REQUEST_CREW_INFORMATION,
  OUTAGE_REQUEST_CANCEL,
  OUTAGE_REQUEST_REJECT,
  OUTAGE_REQUEST_STUDY,
  OUTAGE_REQUEST_SCHEDULE,
  OUTAGE_REQUEST_CHANGE_REJECT,
  OUTAGE_REQUEST_APPROVE,
  OUTAGE_REQUEST_CHANGE_APPROVE,
  OUTAGE_REQUEST_SWITCHING,
  OUTAGE_REQUEST_SYSTEM,
  OUTAGE_REQUEST_COST,
  OUTAGE_REQUEST_ASSIGN_SCHEDULER,
  REAL_TIME_LOG,
  TROUBLE_TICKET,
  TROUBLE_JOB,

  LER_READ,
  OUTAGES_READ,
  OMS_READ,
  DAILY_REPORT_READ,
  TNA_REPORT_READ,
  OMS_AUDIT_REPORT_READ,
  DAILY_ETS_REPORT_READ,
  DAILY_RADIALIZED_SUBSTATION_READ,
  CANCELLED_REPORT_READ,
  REJECTED_REPORT_READ,
  OC_PENDING_REPORT_READ,
  FACILITIES_READ,
  EQUIPMENT_READ,
  SUBSTATIONS_READ,
  SUBSTATION_COUNT_READ,
  USER_GROUPS_READ,
  GENERAL_SETTINGS_READ,
  REPORT_SYSTEM_CHANGE_STATUS_READ,
  SHORT_DESCRIPTION_CATEGORIES_READ,

  EQUIPMENT_CREATE,
  EQUIPMENT_UPDATE,
  EQUIPMENT_DELETE,
  FACILITIES_CREATE,
  FACILITIES_UPDATE,
  FACILITIES_DELETE,
  SUBSTATION_CREATE,
  SUBSTATION_UPDATE,
  SUBSTATION_DELETE,
  SUBSTATION_COUNT_UPLOAD,
  SUBSTATION_COUNT_UPDATE,
  USER_GROUP_CREATE,
  USER_GROUP_UPDATE,
  USER_GROUP_DELETE,
  GENERAL_SETTINGS_UPDATE,
  OUTAGE_CREATE,
  OUTAGE_UPDATE,
  OUTAGE_CANCEL,
  OUTAGE_REJECT,
  OUTAGE_VIEW,
  OUTAGE_SEARCH,
  OUTAGE_EMAIL,

  FORCED_OUTAGE_READ,
  FORCED_OUTAGE_GOS,
  FORCED_OUTAGE_KEARNY,
}

export const AccessPermission = {
  [AccessRole.MCC_OUTAGE_SCHEDULER]: [
    AccessType.OUTAGE_REQUEST_READ,
    AccessType.OUTAGE_REQUEST_AUTH_STATEMENT,
    AccessType.OUTAGE_REQUEST_ISO,
    AccessType.OUTAGE_REQUEST_FACILITY,
    AccessType.OUTAGE_REQUEST_DATE,
    AccessType.OUTAGE_REQUEST_JOB_INFORMATION,
    AccessType.OUTAGE_REQUEST_CREW_INFORMATION,
    AccessType.OUTAGE_REQUEST_CANCEL,
    AccessType.OUTAGE_REQUEST_REJECT,
    AccessType.OUTAGE_REQUEST_CHANGE_REJECT,
    AccessType.OUTAGE_REQUEST_APPROVE,
    AccessType.OUTAGE_REQUEST_CHANGE_APPROVE,
    AccessType.OUTAGE_REQUEST_SWITCHING,
    AccessType.OUTAGE_REQUEST_SYSTEM,
    AccessType.OUTAGE_REQUEST_COST,
    AccessType.LER_READ,
    AccessType.OUTAGES_READ,
    AccessType.OMS_READ,
    AccessType.DAILY_REPORT_READ,
    AccessType.TNA_REPORT_READ,
    AccessType.OMS_AUDIT_REPORT_READ,
    AccessType.DAILY_ETS_REPORT_READ,
    AccessType.DAILY_RADIALIZED_SUBSTATION_READ,
    AccessType.CANCELLED_REPORT_READ,
    AccessType.REJECTED_REPORT_READ,
    AccessType.OC_PENDING_REPORT_READ,
    AccessType.FACILITIES_READ,
    AccessType.SHORT_DESCRIPTION_CATEGORIES_READ,
    AccessType.EQUIPMENT_READ,
    AccessType.SUBSTATIONS_READ,
    AccessType.SUBSTATION_COUNT_READ,
    AccessType.USER_GROUPS_READ,
    AccessType.GENERAL_SETTINGS_READ,
    AccessType.OUTAGE_CREATE,
    AccessType.OUTAGE_UPDATE,
    AccessType.OUTAGE_CANCEL,
    AccessType.OUTAGE_REJECT,
    AccessType.OUTAGE_REQUEST_STUDY,
    AccessType.OUTAGE_VIEW,
    AccessType.OUTAGE_REQUEST_ASSIGN_SCHEDULER,
    AccessType.OUTAGE_REQUEST_SCHEDULE,
    AccessType.REPORT_SYSTEM_CHANGE_STATUS_READ,
  ],
  [AccessRole.MCC_SUBMITTING_SUPERVISOR]: [
    AccessType.OUTAGE_REQUEST_READ,
    AccessType.OUTAGE_REQUEST_CANCEL,
    AccessType.OUTAGE_REQUEST_REJECT,
    AccessType.OUTAGE_REQUEST_CHANGE_REJECT,
    AccessType.OUTAGE_REQUEST_APPROVE,
    AccessType.OUTAGE_REQUEST_CHANGE_APPROVE,
    AccessType.LER_READ,
    AccessType.OUTAGES_READ,
    AccessType.DAILY_REPORT_READ,
    AccessType.OUTAGE_CREATE,
    AccessType.OUTAGE_UPDATE,
    AccessType.OUTAGE_CANCEL,
    AccessType.OUTAGE_REJECT,
    AccessType.OUTAGE_VIEW,
    AccessType.OUTAGE_REQUEST_FACILITY,
    AccessType.OUTAGE_REQUEST_DATE,
    AccessType.OUTAGE_REQUEST_JOB_INFORMATION,
    AccessType.OUTAGE_REQUEST_CREW_INFORMATION,
    AccessType.OUTAGE_REQUEST_WITHDRAW,
    AccessType.DAILY_RADIALIZED_SUBSTATION_READ,
  ],
  [AccessRole.MCC_OPERATIONAL_ENGINEER]: [
    AccessType.OUTAGE_REQUEST_READ,
    AccessType.OUTAGE_REQUEST_CANCEL,
    AccessType.OUTAGE_REQUEST_REJECT,
    AccessType.OUTAGE_REQUEST_CHANGE_REJECT,
    AccessType.OUTAGE_REQUEST_APPROVE,
    AccessType.OUTAGE_REQUEST_CHANGE_APPROVE,
    AccessType.LER_READ,
    AccessType.OUTAGES_READ,
    AccessType.DAILY_REPORT_READ,
    AccessType.DAILY_RADIALIZED_SUBSTATION_READ,
    AccessType.CANCELLED_REPORT_READ,
    AccessType.REJECTED_REPORT_READ,
    AccessType.FACILITIES_READ,
    AccessType.OUTAGE_CREATE,
    AccessType.OUTAGE_UPDATE,
    AccessType.OUTAGE_CANCEL,
    AccessType.OUTAGE_REJECT,
    AccessType.OUTAGE_VIEW,
    AccessType.OUTAGE_REQUEST_FACILITY,
    AccessType.OUTAGE_REQUEST_DATE,
    AccessType.OUTAGE_REQUEST_JOB_INFORMATION,
    AccessType.OUTAGE_REQUEST_CREW_INFORMATION,
    AccessType.OUTAGE_REQUEST_WITHDRAW,
    AccessType.REPORT_SYSTEM_CHANGE_STATUS_READ,
    AccessType.TNA_REPORT_READ,
  ],
  [AccessRole.MCC_OUTAGE_REQUESTOR]: [
    AccessType.OUTAGE_REQUEST_READ,
    AccessType.OUTAGE_REQUEST_CANCEL,
    AccessType.LER_READ,
    AccessType.OUTAGES_READ,
    AccessType.DAILY_REPORT_READ,
    AccessType.OUTAGE_CREATE,
    AccessType.OUTAGE_UPDATE,
    AccessType.OUTAGE_CANCEL,
    AccessType.OUTAGE_VIEW,
    AccessType.OUTAGE_REQUEST_FACILITY,
    AccessType.OUTAGE_REQUEST_DATE,
    AccessType.OUTAGE_REQUEST_JOB_INFORMATION,
    AccessType.OUTAGE_REQUEST_CREW_INFORMATION,
    AccessType.OUTAGE_REQUEST_WITHDRAW,
  ],
  [AccessRole.MCC_REAL_TIME_OPERATOR]: [
    AccessType.OUTAGE_REQUEST_READ,
    AccessType.OUTAGES_READ,
    AccessType.OMS_READ,
    AccessType.DAILY_REPORT_READ,
    AccessType.OMS_AUDIT_REPORT_READ,
    AccessType.DAILY_ETS_REPORT_READ,
    AccessType.DAILY_RADIALIZED_SUBSTATION_READ,
    AccessType.OUTAGE_VIEW,
    AccessType.OUTAGE_REQUEST_SWITCHING,
    AccessType.OUTAGE_REQUEST_SYSTEM,
    AccessType.REPORT_SYSTEM_CHANGE_STATUS_READ,
  ],
  [AccessRole.MCC_OUTAGE_VIEWER]: [
    AccessType.OUTAGE_REQUEST_READ,
    AccessType.OUTAGES_READ,
    AccessType.DAILY_REPORT_READ,
    AccessType.DAILY_RADIALIZED_SUBSTATION_READ,
    AccessType.OUTAGE_VIEW,
  ],
  [AccessRole.ROLES_ADMINISTRATOR]: [AccessType.ROLES],
  [AccessRole.GCC_KEY_ADMINISTRATOR]: [AccessType.KEYS],
  [AccessRole.GCC_KEY_REQUESTOR]: [AccessType.KEYS],
  [AccessRole.GCC_SUBENTRY_LOG_UPDATE]: [
    AccessType.SUBSTATION_ENTRY_LOG_READ,
    AccessType.SUBSTATION_ENTRY_LOG_UPDATE,
  ],
  [AccessRole.GCC_SUBENTRY_LOG_VIEW]: [AccessType.SUBSTATION_ENTRY_LOG_READ],
  [AccessRole.GCC_AUTH_LIST_ADMIN]: [AccessType.AUTH_LIST],
  [AccessRole.REAL_TIME_LOG_VIEWER]: [AccessType.REAL_TIME_LOG],
  [AccessRole.REAL_TIME_LOG_UPDATER]: [AccessType.REAL_TIME_LOG],
  [AccessRole.TROUBLE_TICKET_REQUESTOR]: [AccessType.TROUBLE_TICKET],
  [AccessRole.TROUBLE_TICKET_RESOLVER]: [AccessType.TROUBLE_TICKET],
  [AccessRole.TROUBLE_TICKET_VIEWER]: [AccessType.TROUBLE_TICKET],

  [AccessRole.TROUBLE_JOB_VIEWER]: [AccessType.TROUBLE_JOB],
  [AccessRole.TROUBLE_JOB_UPDATER]: [AccessType.TROUBLE_JOB],
  [AccessRole.GCC_AUTH_LIST_VIEWER]: [AccessType.AUTH_LIST],

  [AccessRole.MCC_LER_APPROVERS]: [
    AccessType.OUTAGE_EMAIL,
    AccessType.OUTAGE_REQUEST_READ,
  ],

  [AccessRole.FORCED_OUTAGE_REQUESTOR]: [
    AccessType.FORCED_OUTAGE_KEARNY,
    // AccessType.FORCED_OUTAGE_GOS,
    AccessType.FORCED_OUTAGE_READ,
  ],
  [AccessRole.FORCED_OUTAGE_RESOLVER]: [
    AccessType.FORCED_OUTAGE_KEARNY,
    AccessType.FORCED_OUTAGE_GOS,
    AccessType.FORCED_OUTAGE_READ,
  ],
  [AccessRole.FORCED_OUTAGE_VIEWER]: [AccessType.FORCED_OUTAGE_READ],
};
