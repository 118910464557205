import React, { useCallback, useEffect, useRef } from 'react';
import {
  ATMButton,
  ATMField,
  ATMForm,
  ATMFormProvider,
  ATMGrid,
  ATMHeader,
  ATMIcon,
  ATMSegment,
  ATMTextArea,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import styles from './forced-outage-comments.module.scss';
import {
  IForcedOutageForm,
  IOutageCommentForm,
  OutageCommentFormSchema,
} from 'src/models/forced-outage.model';
import Moment from 'src/libraries/moment.library';
import { getUser } from 'src/libraries/amplify.library';
import { useFieldArray } from 'react-hook-form';

const FormContent: React.FC<{
  formRef?: React.RefObject<HTMLFormElement>;
}> = ({ formRef }) => {
  const {
    control,
    reset,
    formState: { isSubmitSuccessful },
  } = useATMFormContext<IOutageCommentForm>();

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  useEffect(() => {
    reset({
      comment: '',
      archive: null,
    } as any);
  }, [reset, isSubmitSuccessful]);

  return (
    <ATMGrid style={{ maxWidth: 1023 }}>
      <ATMGrid.Row>
        <ATMGrid.Column>
          <ATMField
            name="comment"
            as={ATMTextArea}
            control={control}
            onChange={([_, { value }]) => {
              return value;
            }}
          />
        </ATMGrid.Column>
      </ATMGrid.Row>
      <ATMGrid.Row>
        <ATMGrid.Column>
          <ATMButton
            secondary
            style={{ float: 'right', marginTop: '-1em' }}
            type="button"
            onClick={handleClick}
          >
            {Lang.LBL_FORCED_OUTAGE_COMMENT}
          </ATMButton>
        </ATMGrid.Column>
      </ATMGrid.Row>
    </ATMGrid>
  );
};

type IProps = {
  data: Partial<IForcedOutageForm>;
};

const ForcedOutageCommentsForm: React.FC<IProps> = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const user = getUser();
  const { control, getValues } = useATMFormContext<IForcedOutageForm>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'outageComments',
  });

  const handleSubmit = useCallback(
    (data) => {
      append({
        ...data,
        archive: false,
        updatedAt: new Date(),
        updatedBy: user?.emp_no,
      });
    },
    [append, fields]
  );

  return (
    <>
      <span className={styles.iconRight}>
        <ATMIcon circular name="comment" />
      </span>
      <ATMHeader
        as="h2"
        content={Lang.LBL_COMMENTS}
        className={styles.header}
      />

      <ATMForm
        ref={formRef}
        validationSchema={OutageCommentFormSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <ATMFormProvider {...props}>
            <FormContent formRef={formRef} />
          </ATMFormProvider>
        )}
      </ATMForm>

      {getValues().outageComments?.length !== 0 ? (
        getValues()?.outageComments?.map((comment, i) => (
          <ATMSegment key={i}>
            <ATMGrid columns={2}>
              <ATMGrid.Column width={14}>
                <span style={{ fontWeight: 'bold' }}>
                  {user?.first_name} {user?.last_name}{' '}
                </span>
                <span style={{ color: '#666' }}>
                  {Moment(comment.updatedAt).format('HH:mm MM/DD/YYYY')}
                </span>
              </ATMGrid.Column>
              <ATMGrid.Column width={2}>
                <div>
                  <ATMIcon
                    name="delete"
                    size="small"
                    color="grey"
                    style={{ float: 'right', cursor: 'pointer' }}
                    onClick={() => remove(i)}
                  />
                </div>
              </ATMGrid.Column>
            </ATMGrid>
            <div style={{ marginTop: '.5em' }}>{comment.comment}</div>
          </ATMSegment>
        ))
      ) : (
        <ATMSegment
          style={{
            color: '#666666',
            display: 'flex',
            justifyContent: 'center',
          }}
          content={Lang.LBL_FORCED_OUTAGE_NO_COMMENTS}
        />
      )}
    </>
  );
};

export default ForcedOutageCommentsForm;
