import React, { useState } from 'react';
import { ATMButton, ATMModal, useATMFormContext } from 'shared-it-appmod-ui';
import { ILerRequestForm } from 'src/models/ler-request.model';

type IProp = {
  onConfirm: () => void;
};

const ForcedOutageRequestConfirmUnfinished: React.FC<IProp> = ({
  onConfirm,
}) => {
  const {
    formState: { isDirty },
  } = useATMFormContext<ILerRequestForm>();
  const [isOpen, setIsOpen] = useState(false);

  if (!isDirty) {
    return (
      <ATMButton
        type="button"
        color="grey"
        basic
        icon="close"
        onClick={onConfirm}
      />
    );
  }

  return (
    <ATMModal
      open={isOpen}
      size="tiny"
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      trigger={<ATMButton type="button" color="grey" basic icon="close" />}
    >
      <ATMModal.Header>Unfinished Request</ATMModal.Header>
      <ATMModal.Content>
        <p>Are you sure you want to close this unfinished request?</p>
        <p>You will lose all your current progress.</p>
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton
          secondary
          content="Cancel"
          onClick={() => setIsOpen(false)}
        />
        <ATMButton primary content="Confirm" onClick={onConfirm} />
      </ATMModal.Actions>
    </ATMModal>
  );
};

export default ForcedOutageRequestConfirmUnfinished;
