/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IORGDataTableQueryState } from 'shared-it-appmod-ui';

import { useForcedOutageContext } from 'src/contexts/forced-outage.context';
import { getForcedOutageStatus } from 'src/selectors/forced-outage.selector';
import { forcedOutageListActionTypes } from 'src/ducks/forced-outage.duck';
import ForcedOutageListView from './forced-outage-list.view';
import Lang from 'src/libraries/language';
import { buildExportData, convertToCSV } from 'src/selectors/file.selector';
import { cleanSpecialCharacterData } from 'src/helpers/file-export.helper';
import moment from 'moment';
import { checkValue } from 'src/libraries/common.library';
import { IForcedOutage } from 'src/models/forced-outage.model';
import { ToastWarning } from 'src/components/atoms/toaster/toaster.component';
import {
  ForcedOutagePage,
  ForcedOutageStatus,
} from 'src/constants/forced-outage.constants';
import useLocationTab, { useLocationParams } from 'src/hooks/location-tab.hook';
import { getDuration } from 'src/helpers/forced-outage.helper';

type IProps = {
  isSearch: boolean;
};

const ForcedOutageList: React.FC<IProps> = ({ isSearch }) => {
  const { state, actions } = useForcedOutageContext();
  const [noData, setNoData] = useState<string>('No records to display');
  const [refresh, setRefresh] = useState(false);

  const [query, setQuery] = useState<Partial<IORGDataTableQueryState>>();

  const formRef = useRef<HTMLFormElement>(null);

  const status = getForcedOutageStatus(
    state,
    forcedOutageListActionTypes.FORCED_OUTAGE_LIST_DATA_READ
  );

  const searchStatus = getForcedOutageStatus(
    state,
    forcedOutageListActionTypes.FORCED_OUTAGE_SEARCH_LIST_DATA_READ
  );

  const { handleTabChange } = useLocationTab('id');

  useEffect(() => {
    actions?.clearList();
    if (!isSearch) {
      actions?.listGET({
        page: 1,
        limit: 0,
        filters: [
          {
            name: 'status',
            value: [
              ForcedOutageStatus.UnderReviewGOS,
              ForcedOutageStatus.UnderReviewKearny,
            ],
          },
        ],
      });
    }
  }, [actions, handleTabChange, isSearch]);

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData) => {
      if (query && !Object.keys(formData).includes('filters')) {
        const response = await actions?.searchListGET({
          ...formData,
          ...query,
          limit: 25,
          page: formData.page,
        });

        if (response.payload?.count === 0) {
          setNoData('No Records Found for the search criteria');
          ToastWarning('No Results found for the search criteria');
        }
      } else {
        if (formData) {
          await actions?.searchListGET({
            ...formData,
            limit: formData?.limit ?? 25,
            page: formData?.page,
          });
        }
      }

      setRefresh(false);
    },
    [actions, query, setNoData]
  );

  const handleSearch = useCallback(
    (params) => {
      if (params) {
        const filters = Object.entries(params).reduce(
          (items: { name: string; value: any }[], [key, value]) => {
            if (value) {
              return [
                ...items,
                {
                  name: key,
                  value,
                },
              ];
            }
            return items;
          },
          []
        );
        setImmediate(() => {
          setQuery((values) => {
            const formData = !values
              ? { page: 1, limit: 25, filters }
              : { ...values, filters };
            handleSubmit(formData);
            return formData;
          });
        });
      } else {
        setQuery(undefined);
        // actions.clearList();
      }
    },
    [setQuery, actions, handleSubmit]
  );

  const handleDownload = useCallback(
    (data: IForcedOutage[]) => {
      if (data.length) {
        const items = data.map((value) => [
          checkValue(value.outageId),
          checkValue(Lang.FORCED_OUTAGE_STATUS[value.status ?? '']),
          checkValue(value?.facility?.outgFacNm),
          checkValue(Lang.FACILITY_TYPE[value?.facilityTypeId ?? '']),
          checkValue(value?.startDate),
          checkValue(value?.endDate),
          getDuration(value),
          checkValue(value?.causeCode?.description),
          checkValue(value?.suppCauseCode?.description),
        ]);

        const { exportData, format } = convertToCSV(
          buildExportData(items, [
            Lang.LBL_FORCED_OUTAGE_ID,
            Lang.LBL_FORCED_OUTAGE_REQUEST_STATUS,
            Lang.LBL_FORCED_OUTAGE_FACILITY_NAME,
            Lang.LBL_FORCED_OUTAGE_FACILITY_TYPE,
            Lang.LBL_FORCED_OUTAGE_START_DATE,
            Lang.LBL_FORCED_OUTAGE_END_DATE,
            Lang.LBL_FORCED_OUTAGE_DURATION,
            Lang.LBL_FORCED_OUTAGE_PRIMARY_CAUSE,
            Lang.LBL_FORCED_OUTAGE_SECONDARY_CAUSE,
          ])
        );
        const link = document.createElement('a');
        link.setAttribute('href', cleanSpecialCharacterData(exportData));
        link.setAttribute(
          'download',
          `MCC_Forced_Outages_${moment().format('YYYYMMDD')}.${format}`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    [actions]
  );

  const {
    params: { forcedOutageView, forcedOutageEdit },
  } = useLocationParams([ForcedOutagePage.View, ForcedOutagePage.Edit]);

  // This will refresh the list if the detail/edit page is closed
  useEffect(() => {
    if (refresh && !forcedOutageView && !forcedOutageEdit) {
      handleSubmit(query);
    }
  }, [
    refresh,
    forcedOutageView,
    forcedOutageEdit,
    query,
    setRefresh,
    handleSubmit,
  ]);

  useEffect(() => {
    if (forcedOutageView || forcedOutageEdit) {
      setRefresh(true);
    }
  }, [forcedOutageView, forcedOutageEdit, setRefresh]);

  return (
    <ForcedOutageListView
      data={isSearch ? state.searchList : state?.list}
      noData={noData}
      isSearch={isSearch}
      loading={status.fetching || searchStatus.fetching}
      handleClick={handleClick}
      handleSubmit={handleSubmit}
      handleSearch={handleSearch}
      handleDownload={handleDownload}
    />
  );
};

export default ForcedOutageList;
