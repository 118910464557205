import React, { useState } from 'react';
import {
  IForcedOutage,
  IForcedOutageForm,
} from 'src/models/forced-outage.model';
import styles from './forced-outage-overview.module.scss';
import {
  ATMAccordion,
  ATMGrid,
  ATMHeader,
  ATMIcon,
  MOLReadonlyField,
} from 'shared-it-appmod-ui';
import Lang from 'src/libraries/language';
import { causeCdOptions } from './forced-outage-overview-form.component';
import { durationComputation } from 'src/helpers/forced-outage.helper';

type IProps = {
  data?: Partial<IForcedOutageForm>;
  details?: IForcedOutage;
  handleEdit?: () => void;
};

const ForcedOutagePanelOverview: React.FC<IProps> = ({ data, handleEdit }) => {
  const [active, setActive] = useState(true);
  if (!data) {
    return null;
  }

  const getStartEndDtTm = (date: Date, time: string) => {
    let day = `${date?.getDate()}`;
    let month = `${date?.getMonth()}`;
    const year = date?.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }

    if (day.length < 2) {
      day = '0' + day;
    }

    return `${month}/${day}/${year} ${time}`;
  };

  const overviewDetails = (
    <>
      <span className={styles.iconRight}>
        <ATMIcon circular name="cogs" />
      </span>
      <ATMHeader
        as="h2"
        content={Lang.TTL_OUTAGE_OVERVIEW}
        className={styles.header}
      />
      <ATMGrid columns={2}>
        <ATMGrid.Row>
          <ATMGrid.Column>
            <MOLReadonlyField
              label={Lang.LBL_START_DATE}
              value={
                data.startDate && data.startTm
                  ? getStartEndDtTm(data.startDate, data.startTm)
                  : '-'
              }
              inline={false}
            />
          </ATMGrid.Column>
          <ATMGrid.Column>
            <MOLReadonlyField
              label={Lang.LBL_END_DATE}
              value={
                data.endDate && data.endTm
                  ? getStartEndDtTm(data.endDate, data.endTm)
                  : '-'
              }
              inline={false}
            />
          </ATMGrid.Column>
          <ATMGrid.Column>
            <MOLReadonlyField
              label={Lang.LBL_FORCED_OUTAGE_DURATION}
              value={
                data.startDate && data.startTm && data.endDate && data.endTm
                  ? durationComputation(
                      data.startDate,
                      data.startTm,
                      data.endDate,
                      data.endTm
                    )
                  : '-'
              }
              inline={false}
            />
          </ATMGrid.Column>

          <ATMGrid.Column width={16}>
            <MOLReadonlyField
              label={Lang.LBL_DESCRIPTION}
              value={data.description ?? '-'}
              inline={false}
            />
          </ATMGrid.Column>
          <ATMGrid.Column width={16}>
            <MOLReadonlyField
              label={Lang.LBL_FORCED_OUTAGE_CAUSE_CODE}
              value={
                causeCdOptions.find((cd) => cd.value === data.causeCd)?.text ??
                '-'
              }
              inline={false}
            />
          </ATMGrid.Column>
          <ATMGrid.Column width={16}>
            <MOLReadonlyField
              label={Lang.LBL_FORCED_OUTAGE_SUPPLEMENTAL_CC}
              value={
                causeCdOptions.find((val) => val.value === data.suppCauseCd)
                  ?.text ?? '-'
              }
              inline={false}
            />
          </ATMGrid.Column>
        </ATMGrid.Row>
      </ATMGrid>
    </>
  );
  return data ? (
    overviewDetails
  ) : (
    <ATMAccordion style={{ marginBottom: '1rem' }} fluid>
      {/* View of FO Overview */}
      <ATMAccordion.Title
        active={active}
        index={0}
        onClick={() => setActive((val) => !val)}
      >
        <ATMIcon name="angle down" />
        {Lang.TTL_OUTAGE_OVERVIEW}

        {handleEdit && (
          <a className={styles.edit} onClick={handleEdit} role="button">
            <ATMIcon name="edit" />
            {Lang.LBL_EDIT}
          </a>
        )}
      </ATMAccordion.Title>
    </ATMAccordion>
  );
};

export default ForcedOutagePanelOverview;
