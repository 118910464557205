/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-return-assign */
import { format } from 'date-fns';
import moment from 'moment';
import Lang from 'src/libraries/language';
import {
  REAL_TIME_LOG_BPOR_REPORT_COMMON_CONTENT,
  REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL,
  REAL_TIME_LOG_EMAIL_FIELD_LABEL,
  RealTimeLogAuditCategory,
  RealTimeLogCategory,
  SYSTEM_CHANGES_FIELD_LABEL,
} from 'src/constants/real-time-log.constant';
import {
  IBporReport,
  IForcedLogList,
  IForcedOutageConfirmationForm,
  IRealTimeLog,
  IRealTimeLogFieldModel,
} from 'src/models/real-time-log.model';
import { ISystemChangeReport } from 'src/models/system-change.model';
import { getIsAdmin } from 'src/libraries/access.library';
import { AccessRole } from 'src/constants';
import { getUser } from 'src/libraries/amplify.library';

export const dateTimeFormat = 'MMM dd, yyyy (EEE) HH:mm';
export const timeFormat = 'HH:mm';
export const removeComma = (data: any) => {
  return data?.toString()?.replace(/,/g, '') ?? '';
};

export const generateRealTimeLogEmailFormDetails = (
  data: Partial<IRealTimeLog>,
  fullDetails = false
): IForcedOutageConfirmationForm => {
  let content = '';
  let subject = '';
  let historyContent = '';
  const sentAuditLog = data.auditLogs?.find(
    (i) => i.auditType === RealTimeLogAuditCategory.EMAIL_SENT
  );
  subject = sentAuditLog
    ? 'Update Real-Time Interruption: '
    : 'Real-Time Interruption: ';
  if (fullDetails) {
    const NL = '\n';
    const mailBody: Record<
      REAL_TIME_LOG_EMAIL_FIELD_LABEL,
      IRealTimeLogFieldModel
    > = {
      [REAL_TIME_LOG_EMAIL_FIELD_LABEL.DATE_TIME]: {
        fieldTitle: REAL_TIME_LOG_EMAIL_FIELD_LABEL.DATE_TIME,
        fieldValue: data.logDtTm ? format(data.logDtTm, dateTimeFormat) : '',
      },
      [REAL_TIME_LOG_EMAIL_FIELD_LABEL.CATEGORY]: {
        fieldTitle: REAL_TIME_LOG_EMAIL_FIELD_LABEL.CATEGORY,
        fieldValue: data.logCategory,
      },
      [REAL_TIME_LOG_EMAIL_FIELD_LABEL.LINE]: {
        fieldTitle: REAL_TIME_LOG_EMAIL_FIELD_LABEL.LINE,
        fieldValue: data.facility?.outgFacNm,
      },
      [REAL_TIME_LOG_EMAIL_FIELD_LABEL.SUBSTATION]: {
        fieldTitle: REAL_TIME_LOG_EMAIL_FIELD_LABEL.SUBSTATION,
        fieldValue: `${data.substation?.name as any} ${NL}${
          REAL_TIME_LOG_EMAIL_FIELD_LABEL.EQUIPMENT
        } ${data.facility?.outgFacNm} - ${data.facility?.volt?.voltNm}kV - ${
          data.facility?.facTyp?.facTypNm
        }`,
      },
      [REAL_TIME_LOG_EMAIL_FIELD_LABEL.EQUIPMENT_TYPE]: {
        fieldTitle: REAL_TIME_LOG_EMAIL_FIELD_LABEL.EQUIPMENT_TYPE,
        fieldValue: data.facility?.facTyp?.facTypNm,
      },
      [REAL_TIME_LOG_EMAIL_FIELD_LABEL.VOLTAGE]: {
        fieldTitle: REAL_TIME_LOG_EMAIL_FIELD_LABEL.VOLTAGE,
        fieldValue: `${data.facility?.volt?.voltNm}kV`,
      },
      [REAL_TIME_LOG_EMAIL_FIELD_LABEL.EQUIPMENT]: {
        fieldTitle: REAL_TIME_LOG_EMAIL_FIELD_LABEL.EQUIPMENT,
        fieldValue: data.facility?.outgFacNm,
      },
      [REAL_TIME_LOG_EMAIL_FIELD_LABEL.SUBS_INTERRUPTED]: {
        fieldTitle: REAL_TIME_LOG_EMAIL_FIELD_LABEL.SUBS_INTERRUPTED,
        fieldValue: data.forcedLogSubs?.length
          ? `${data?.forcedLogSubs
              ?.map((val) => ` ${val.substation.name}`)
              .toString()
              .trim()}`
          : 'None',
      },
      [REAL_TIME_LOG_EMAIL_FIELD_LABEL.DATE_TIME_OCCURED]: {
        fieldTitle: REAL_TIME_LOG_EMAIL_FIELD_LABEL.DATE_TIME_OCCURED,
        fieldValue: data.logDtTmFrcdOutOccurred
          ? format(data.logDtTmFrcdOutOccurred, dateTimeFormat)
          : '',
      },
      [REAL_TIME_LOG_EMAIL_FIELD_LABEL.DATE_TIME_RECOVERED]: {
        fieldTitle: REAL_TIME_LOG_EMAIL_FIELD_LABEL.DATE_TIME_RECOVERED,
        fieldValue: data.logDtTmFrcdOutRestored
          ? format(data.logDtTmFrcdOutRestored, dateTimeFormat)
          : '',
      },
      [REAL_TIME_LOG_EMAIL_FIELD_LABEL.LOG_ENTRY]: {
        fieldTitle: REAL_TIME_LOG_EMAIL_FIELD_LABEL.LOG_ENTRY,
        fieldValue: data.logEntry?.length ? data.logEntry : '',
      },
    };

    Object.keys(mailBody).forEach((keyValue) => {
      const field = mailBody[keyValue as REAL_TIME_LOG_EMAIL_FIELD_LABEL];

      switch (keyValue) {
        case REAL_TIME_LOG_EMAIL_FIELD_LABEL.DATE_TIME:
          content += `${field.fieldTitle} ${field.fieldValue}\n`;
          break;
        case REAL_TIME_LOG_EMAIL_FIELD_LABEL.CATEGORY: {
          content += `${field.fieldTitle} ${field.fieldValue}\n`;
          content += '\n';
          break;
        }
        case REAL_TIME_LOG_EMAIL_FIELD_LABEL.LINE: {
          if (!data.substation) {
            subject += `${data.facility?.volt?.voltNm}kV ${data.facility?.outgFacNm}`;
            content += `${field.fieldTitle} ${field.fieldValue}\n`;
            content += '\n';
          }
          break;
        }
        case REAL_TIME_LOG_EMAIL_FIELD_LABEL.SUBSTATION: {
          if (data.substation) {
            subject += `${data.facility?.volt?.voltNm}kV ${
              data.substation?.name as any
            }`;
            content += `${field.fieldTitle} ${field.fieldValue}\n`;
            content += '\n';
          }
          break;
        }
        case REAL_TIME_LOG_EMAIL_FIELD_LABEL.SUBS_INTERRUPTED:
          if (field.fieldValue?.length) {
            content += `${field.fieldTitle}\n${field.fieldValue}\n`;
          }
          break;
        case REAL_TIME_LOG_EMAIL_FIELD_LABEL.DATE_TIME_OCCURED:
          if (field.fieldValue?.length) {
            content += '\n';
            content += `${field.fieldTitle} ${field.fieldValue}\n`;
          }
          break;
        case REAL_TIME_LOG_EMAIL_FIELD_LABEL.DATE_TIME_RECOVERED: {
          if (field.fieldValue?.length) {
            content += `${field.fieldTitle} ${field.fieldValue}\n`;
          }
          break;
        }
        case REAL_TIME_LOG_EMAIL_FIELD_LABEL.LOG_ENTRY:
          if (field.fieldValue?.length) {
            content += '\n';
            content += `${field.fieldTitle}\n${field.fieldValue}`;
            content += '\n';
          }
          break;
        default:
          break;
      }
    });
  } else if (
    data.logCategory === RealTimeLogCategory.FORCEDOUTAGE ||
    data.logCategory === RealTimeLogCategory.RASOPERATION
  ) {
    let kvFacility = '';
    if (!data.substation) {
      kvFacility += `${data.facility?.volt?.voltNm}kV ${data.facility?.outgFacNm}`;
    }

    if (data.substation) {
      kvFacility += `${data.facility?.volt?.voltNm}kV ${
        data.substation?.name as any
      }`;
    }

    subject += kvFacility;

    if (data.logCategory === RealTimeLogCategory.RASOPERATION) {
      subject += ' RAS Operation';
    }

    const subsInterrupted = data.forcedLogSubs?.length
      ? `${data?.forcedLogSubs
          ?.map((val) => ` ${val.substation.name}`)
          .toString()
          .trim()}`
      : null;

    const subsLength = data.forcedLogSubs?.length ?? 0;
    if (data.logCategory === RealTimeLogCategory.RASOPERATION) {
      content += data.logEntry;
    } else {
      if (data.logDtTmFrcdOutRestored) {
        content += `At ${
          data.logDtTmFrcdOutRestored.getHours().toString().padStart(2, '0') +
          data.logDtTmFrcdOutRestored.getMinutes().toString().padStart(2, '0')
        } ${`${kvFacility} tested successfully and returned to service. `}`;
      } else {
        content += `At ${
          data.logDtTmFrcdOutOccurred
            ? data.logDtTmFrcdOutOccurred
                .getHours()
                .toString()
                .padStart(2, '0') +
              data.logDtTmFrcdOutOccurred
                .getMinutes()
                .toString()
                .padStart(2, '0')
            : '--'
        } ${
          data.substation
            ? `the ${data.substation.name} ${
                subsInterrupted?.length
                  ? 'substation with XX MW load was dropped.'
                  : 'Relayed/Tripped/Opened.'
              }`
            : `${kvFacility} Relayed/Tripped/Opened.`
        } Cause under investigation. CAISO and SCM/TCM/SPM notified. `;
        // ${kvFacility} Relayed/Tripped/Opened. Cause under investigation. CAISO and SCM/TCM/SPM notified. `;
      }
    }
    if (subsInterrupted) {
      content += `{The ${subsInterrupted} substation${
        subsLength > 1 ? 's' : ''
      } `;
      content += `${subsLength > 1 ? 'were' : 'was'} dropped.}`;
    }
  } else {
    let kvFacility = '';
    if (!data.substation) {
      kvFacility += `${data.facility?.volt?.voltNm}kV ${data.facility?.outgFacNm}`;
    }

    if (data.substation) {
      kvFacility += `${data.facility?.volt?.voltNm}kV ${
        data.substation?.name as any
      }`;
    }

    subject += kvFacility;
    // subject += '<edit subject here>';
    content = data.logEntry ?? '';
  }

  if (data.auditLogs && data.auditLogs.length > 0) {
    const { length } = data.auditLogs;
    data.auditLogs.map((item, index) => {
      if (item.emailContent) {
        if (index !== length - 1) {
          if (data.logCategory === RealTimeLogCategory.FORCEDOUTAGE) {
            historyContent += `${format(item.updatedAt, dateTimeFormat)}:<br>${
              item.emailContent
            }\n `;
          } else {
            historyContent += `${format(
              item.updatedAt,
              dateTimeFormat
            )}:<br>${item.emailContent.replace(/\n/g, '<br>')}\n `;
          }
        } else if (data.logCategory === RealTimeLogCategory.FORCEDOUTAGE) {
          // const tempContent = item.emailContent.repla
          historyContent += `${format(item.updatedAt, dateTimeFormat)}:<br>${
            item.emailContent
          }`;
        } else {
          historyContent += `${format(
            item.updatedAt,
            dateTimeFormat
          )}:<br>${item.emailContent.replace(/\n/g, '<br>')}`;
        }
      }
    });
  }
  return {
    logId: data.logId as number,
    mailSubject: subject,
    mailContent: content,
    mailHistoryContent: historyContent,
  };
};

// Generate BPOR Report
export const generateRealTimeLogBPORReport = (
  data: IBporReport | null,
  date: Date
): IForcedOutageConfirmationForm => {
  const { NONE } = REAL_TIME_LOG_BPOR_REPORT_COMMON_CONTENT;
  const NBSP = '&nbsp;';

  const sortedForcedInterruptionsData = data?.rows?.forcedInterruptions
    ?.filter((val) => val.logCategory === RealTimeLogCategory.FORCEDOUTAGE)
    ?.sort((a: any, b: any) =>
      a?.logCategory?.toLowerCase()?.localeCompare(b?.logCategory.toLowerCase())
    );

  const sortedItemsOfInterestsData = data?.rows?.forcedInterruptions?.sort(
    (a: any, b: any) =>
      a?.logCategory?.toLowerCase()?.localeCompare(b?.logCategory.toLowerCase())
  );

  const sortedCompletedSystemChanges = data?.rows?.completedSystemChanges?.sort(
    (a: any, b: any) => a?.requestId - b?.requestId
  );

  const applyNoBreakingSpace = (
    fieldName:
      | REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL
      | SYSTEM_CHANGES_FIELD_LABEL,
    fieldValue?
  ) => {
    const applySpaceLimit = (limit: number) => {
      let nbsp = '';
      for (let i = 0; i < limit; i++) {
        nbsp += NBSP;
      }
      return nbsp;
    };
    switch (fieldName) {
      case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TL: {
        return applySpaceLimit(80);
      }
      case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TERMINALS: {
        return applySpaceLimit(64);
      }
      case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.SUBS_INTERRUPTED: {
        return applySpaceLimit(47);
      }
      case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TIME_OCCURED: {
        return applySpaceLimit(54);
      }
      case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TIME_RESTORED: {
        return applySpaceLimit(55);
      }
      case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.CAUSE: {
        return applySpaceLimit(72);
      }
      case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TRIPS_AND_TARGETS: {
        return fieldValue === NONE ? applySpaceLimit(48) : applySpaceLimit(79);
      }
      case SYSTEM_CHANGES_FIELD_LABEL.SUBSTATION: {
        return applySpaceLimit(62);
      }
      case SYSTEM_CHANGES_FIELD_LABEL.CHANGE: {
        return applySpaceLimit(69);
      }
      case SYSTEM_CHANGES_FIELD_LABEL.LER: {
        return applySpaceLimit(75);
      }
      default:
        break;
    }
    return '';
  };

  // Facility Information builder
  const getFacilityInfo = (
    isTerminal = false,
    bporReport: IRealTimeLog | ISystemChangeReport | null,
    isSystemChange = false,
    isItemsOfInterests = false
  ) => {
    const terminalRegex = /\(([^)]+)\)/g;
    const tlRegex = /\d+/g;
    let tl = NONE;
    let facilityName = '';
    if (isSystemChange) {
      facilityName =
        (
          bporReport as ISystemChangeReport
        )?.outageFacility?.facility?.outgFacNm?.trim() ?? '';
    } else {
      facilityName =
        (bporReport as IRealTimeLog)?.facility?.outgFacNm?.trim() ?? '';
    }
    let terminals = facilityName;
    const getFacility = (splitBy) => facilityName?.split(splitBy);
    let facility: any[] = [];
    const isStartWithTL = (name) => name?.match(/TL\s\d+/gi);

    if (isTerminal && isItemsOfInterests) {
      return (bporReport as IRealTimeLog)?.facility
        ? `${
            (bporReport as IRealTimeLog)?.facility?.volt?.voltNm
          }kV ${facilityName}`
        : NONE;
    }

    if (isStartWithTL(facilityName)) {
      if (getFacility(' ')?.length) {
        facility = getFacility(' ') as any;
        for (let i = 0; i < facility.length; i++) {
          if (facility[i].match(tlRegex) || facility[i].match(/\d+kV/g)) {
            tl = facility[i];
            break;
          }
        }
      }
      if (terminals?.match(/\bat\b/gi)) {
        terminals = terminals.slice(
          terminals?.indexOf(' at ') + 4,
          terminals.length
        );
      }
      if (facilityName?.match(terminalRegex)) {
        const matchResult = facilityName?.match(terminalRegex);
        terminals = matchResult?.length
          ? matchResult[0].replace(/[()]/g, '')
          : NONE;
      }
      if (isTerminal) {
        if (isStartWithTL(terminals)) {
          const matched = isStartWithTL(terminals);
          if (matched?.length) {
            terminals = terminals?.slice(
              terminals?.indexOf(matched[0]) + matched[0].length,
              terminals.length
            );
          }
        }
        return terminals?.trim() ?? NONE;
      }
    } else if (isTerminal) {
      const data = bporReport as IRealTimeLog;
      if (data?.substation) {
        return data?.facility
          ? `${data.facility.volt?.voltNm}kV ${data.substation?.name}`
          : NONE;
      }
      return data?.facility
        ? `${data.facility?.volt?.voltNm}kV ${facilityName}`
        : NONE;
    }
    return tl;
  };

  // Prepare data to match their appropriate field
  const extractReport = (
    bporReport: IRealTimeLog | null,
    systemChanges?: ISystemChangeReport
  ) => {
    if (systemChanges) {
      const systemChangesData: Record<
        SYSTEM_CHANGES_FIELD_LABEL,
        IRealTimeLogFieldModel
      > = {
        [SYSTEM_CHANGES_FIELD_LABEL.SYSTEM_CHANGES]: {
          fieldTitle: SYSTEM_CHANGES_FIELD_LABEL.SYSTEM_CHANGES,
          fieldValue: '',
        },
        [SYSTEM_CHANGES_FIELD_LABEL.SUBSTATION]: {
          fieldTitle: SYSTEM_CHANGES_FIELD_LABEL.SUBSTATION,
          fieldValue: systemChanges?.outageFacility?.substation?.name ?? NONE,
        },
        [SYSTEM_CHANGES_FIELD_LABEL.TL]: {
          fieldTitle: SYSTEM_CHANGES_FIELD_LABEL.TL,
          fieldValue:
            !systemChanges?.outageFacility?.substation &&
            systemChanges?.outageFacility?.facility?.outgFacNm
              ? getFacilityInfo(false, systemChanges, true)
              : NONE,
        },
        [SYSTEM_CHANGES_FIELD_LABEL.CHANGE]: {
          fieldTitle: SYSTEM_CHANGES_FIELD_LABEL.CHANGE,
          fieldValue: systemChanges?.sysChngSummary?.noteTxt ?? NONE,
        },
        [SYSTEM_CHANGES_FIELD_LABEL.LER]: {
          fieldTitle: SYSTEM_CHANGES_FIELD_LABEL.LER,
          fieldValue: `${systemChanges?.requestId}`,
        },
      };
      return systemChangesData;
    }

    const contentBody: Record<
      REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL,
      IRealTimeLogFieldModel
    > = {
      [REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.ITEMS_OF_INTEREST]: {
        fieldTitle: REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.ITEMS_OF_INTEREST,
        fieldValue: '',
      },
      [REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.FORCED_INTERRUPTIONS]: {
        fieldTitle: REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.FORCED_INTERRUPTIONS,
        fieldValue: '',
      },
      [REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TL]: {
        fieldTitle: REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TL,
        fieldValue: bporReport?.facility?.outgFacNm
          ? getFacilityInfo(false, bporReport)
          : NONE,
      },
      [REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TERMINALS]: {
        fieldTitle: REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TERMINALS,
        fieldValue: getFacilityInfo(true, bporReport),
      },
      [REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.SUBS_INTERRUPTED]: {
        fieldTitle: REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.SUBS_INTERRUPTED,
        fieldValue: bporReport?.forcedLogSubs?.length
          ? `${bporReport?.forcedLogSubs
              ?.map((val) => ` ${val.substation.name}`)
              ?.toString()
              ?.trim()}`
          : NONE,
      },
      [REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TIME_OCCURED]: {
        fieldTitle: REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TIME_OCCURED,
        fieldValue: bporReport?.logDtTmFrcdOutOccurred
          ? format(bporReport.logDtTmFrcdOutOccurred, timeFormat)?.replace(
              /:/g,
              ''
            )
          : NONE,
      },
      [REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TIME_RESTORED]: {
        fieldTitle: REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TIME_RESTORED,
        fieldValue: bporReport?.logDtTmFrcdOutRestored
          ? format(bporReport.logDtTmFrcdOutRestored, timeFormat)?.replace(
              /:/g,
              ''
            )
          : NONE,
      },
      [REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.CAUSE]: {
        fieldTitle: REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.CAUSE,
        fieldValue: REAL_TIME_LOG_BPOR_REPORT_COMMON_CONTENT.CAUSE_UNKNOWN,
      },
      [REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TRIPS_AND_TARGETS]: {
        fieldTitle: REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TRIPS_AND_TARGETS,
        fieldValue: bporReport?.forcedLog?.length
          ? JSON.stringify(bporReport.forcedLog)
          : NONE,
      },
    };
    return contentBody;
  };

  const generateDefaultHeader = (fieldTitle) => {
    return `<p><span style="color: #031858"><strong><u>${fieldTitle}</u></strong></span></p><p />`;
  };

  const generateParagraph = (paragraphText) => {
    return `<p>${paragraphText}</p>`;
  };
  const generateHTMLContent = (
    fieldTitle: string,
    fieldvalue: string | null | undefined,
    record: REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL | SYSTEM_CHANGES_FIELD_LABEL,
    forcedLog?: IForcedLogList[]
  ) => {
    let generatedContent = '';
    const createParagraph = (name, value, fieldName?, fieldId?) => {
      return `${fieldId ? `<p id=${fieldId}>` : '<p>'}${
        fieldName ? '&nbsp;&nbsp;' : ''
      }${name}${applyNoBreakingSpace(fieldName || name, value)}${value}</p>`;
    };
    const getGeneratedContent = () => {
      const generateItemsOfInterests = () => {
        let items = '';
        sortedItemsOfInterestsData?.map((val) => {
          switch (val?.logCategory) {
            case Lang.REAL_TIME_LOG_STATUS[
              Lang.REAL_TIME_LOG_STATUS[
                '30+min Unplanned Telemetering/Control Equip Outage'
              ]
            ]:
            case Lang.REAL_TIME_LOG_STATUS[
              Lang.REAL_TIME_LOG_STATUS['Change Control Center']
            ]:
            case Lang.REAL_TIME_LOG_STATUS[
              Lang.REAL_TIME_LOG_STATUS['RC/BA Notification']
            ]:
            case Lang.REAL_TIME_LOG_STATUS[
              Lang.REAL_TIME_LOG_STATUS['Int./Freq. Excursion']
            ]:
            case Lang.REAL_TIME_LOG_STATUS[
              Lang.REAL_TIME_LOG_STATUS['Field Notification']
            ]:
            case Lang.REAL_TIME_LOG_STATUS[
              Lang.REAL_TIME_LOG_STATUS['Emergency Event']
            ]:
            case Lang.REAL_TIME_LOG_STATUS[
              Lang.REAL_TIME_LOG_STATUS['Comm. Failure']
            ]:
            case Lang.REAL_TIME_LOG_STATUS[
              Lang.REAL_TIME_LOG_STATUS['RAS Operation']
            ]:
            case Lang.REAL_TIME_LOG_STATUS[
              Lang.REAL_TIME_LOG_STATUS.Wildfire
            ]: {
              const logEntries = () => {
                let list = '';
                val?.logEntry?.split(/\n/g)?.map((value) => {
                  if (value?.length > 0) {
                    list += `<li><p>${value}</p></li>`;
                  }
                });
                return list;
              };
              const facilityDetail =
                getFacilityInfo(true, val, false, true) === NONE
                  ? ''
                  : ` - ${getFacilityInfo(true, val, false, true)}`;
              const entries = logEntries();
              items += `<p>${val.logCategory}${facilityDetail} </p>${
                entries.length ? `<ul>${entries}</ul>` : ''
              }`;
              break;
            }
            default:
              break;
          }
        });
        return items.length
          ? items
          : `<p>${REAL_TIME_LOG_BPOR_REPORT_COMMON_CONTENT.NOTHING_TO_REPORT}</p>`;
      };

      if (record === REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.ITEMS_OF_INTEREST) {
        return generateItemsOfInterests();
      }

      if (record === REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TRIPS_AND_TARGETS) {
        let tripsAndTargets = '';
        forcedLog?.map((val, i) => {
          tripsAndTargets += createParagraph(
            val?.substation?.substationId,
            val?.tripsTargets,
            record,
            `trips_and_targets_${i}`
          );
        });
        return tripsAndTargets;
      }

      return createParagraph(fieldTitle, fieldvalue);
    };

    switch (record) {
      case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.ITEMS_OF_INTEREST:
        generatedContent += generateDefaultHeader(fieldTitle);
        generatedContent += `${getGeneratedContent()}<p />`;
        break;
      case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.FORCED_INTERRUPTIONS:
        generatedContent += generateDefaultHeader(fieldTitle);
        break;
      case SYSTEM_CHANGES_FIELD_LABEL.SYSTEM_CHANGES:
        generatedContent += `<p />${generateDefaultHeader(fieldTitle)}`;
        break;
      case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TRIPS_AND_TARGETS:
        generatedContent += getGeneratedContent().length
          ? `<p>${fieldTitle}</p>`
          : createParagraph(fieldTitle, fieldvalue);
        generatedContent += `${getGeneratedContent()}`;
        break;
      case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TL:
      case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TERMINALS:
      case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.SUBS_INTERRUPTED:
      case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TIME_OCCURED:
      case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TIME_RESTORED:
      case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.CAUSE:
      case SYSTEM_CHANGES_FIELD_LABEL.SUBSTATION:
      case SYSTEM_CHANGES_FIELD_LABEL.CHANGE:
        generatedContent += getGeneratedContent();
        break;
      case SYSTEM_CHANGES_FIELD_LABEL.LER:
        generatedContent += `${getGeneratedContent()}`;
        break;
      default:
        break;
    }
    return generatedContent;
  };

  const report: Record<
    REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL,
    IRealTimeLogFieldModel
  >[] = [];

  const itemsOfInterestsReport: Record<
    REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL,
    IRealTimeLogFieldModel
  >[] = [];

  sortedForcedInterruptionsData?.map((val) => {
    const extractedReport: any = val && extractReport(val);
    return extractedReport && report.push(extractedReport);
  });

  sortedItemsOfInterestsData?.map((val) => {
    const extractedReport: any = val && extractReport(val);
    return extractedReport && itemsOfInterestsReport.push(extractedReport);
  });

  let content = `<p /><p /><p style="text-align: center">${
    date ? moment(date).format('dddd, MMMM D, YYYY') : ''
  }</p><p />`;

  if (sortedCompletedSystemChanges?.length) {
    content += '<p />';
  }

  // Generate Content Forced Interruptions and Items of Interests
  if (itemsOfInterestsReport.length) {
    itemsOfInterestsReport.map((itemsOfInterests, i) => {
      Object.keys(itemsOfInterests).forEach((keyValue) => {
        const field =
          itemsOfInterests[keyValue as REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL];
        switch (keyValue) {
          case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.ITEMS_OF_INTEREST: {
            if (i === 0) {
              content += generateHTMLContent(
                field.fieldTitle,
                field?.fieldValue,
                keyValue
              );
            }
            break;
          }
          default:
            break;
        }
      });
    });
  } else {
    content += `${generateDefaultHeader(
      REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.ITEMS_OF_INTEREST
    )}`;
    content += `${generateParagraph(
      REAL_TIME_LOG_BPOR_REPORT_COMMON_CONTENT.NOTHING_TO_REPORT
    )}<p />`;
    // content += `${generateDefaultHeader(
    //   REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.FORCED_INTERRUPTIONS
    // )}`;
    // content += `${generateParagraph(
    //   REAL_TIME_LOG_BPOR_REPORT_COMMON_CONTENT.NOTHING_TO_REPORT
    // )}<p />`;
  }

  // Generate Content Forced Interruptions
  if (report.length) {
    report.map((bporReport, i) => {
      Object.keys(bporReport).forEach((keyValue) => {
        const field =
          bporReport[keyValue as REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL];
        switch (keyValue) {
          // case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.ITEMS_OF_INTEREST:
          case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.FORCED_INTERRUPTIONS: {
            if (i === 0) {
              content += generateHTMLContent(
                field.fieldTitle,
                field?.fieldValue,
                keyValue
              );
            }
            break;
          }
          case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TL: {
            if (field?.fieldValue !== NONE) {
              content += generateHTMLContent(
                field.fieldTitle,
                field?.fieldValue,
                keyValue
              );
            }
            break;
          }
          case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TERMINALS:
          case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.SUBS_INTERRUPTED:
          case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TIME_OCCURED:
          case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TIME_RESTORED:
          case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.CAUSE:
            content += generateHTMLContent(
              field.fieldTitle,
              field?.fieldValue,
              keyValue
            );
            break;
          case REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.TRIPS_AND_TARGETS:
            content += generateHTMLContent(
              field.fieldTitle,
              field?.fieldValue,
              keyValue,
              field?.fieldValue !== NONE
                ? JSON.parse(field?.fieldValue ?? '')
                : []
            );
            if (
              sortedForcedInterruptionsData &&
              sortedForcedInterruptionsData?.length > 1 &&
              report.length - 1 !== i
            ) {
              content += '<hr />';
            }
            break;
          default:
            break;
        }
      });
    });
  } else {
    // content += `${generateDefaultHeader(
    //   REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.ITEMS_OF_INTEREST
    // )}`;
    // content += `${generateParagraph(
    //   REAL_TIME_LOG_BPOR_REPORT_COMMON_CONTENT.NOTHING_TO_REPORT
    // )}<p />`;
    content += `${generateDefaultHeader(
      REAL_TIME_LOG_BPOR_REPORT_FIELD_LABEL.FORCED_INTERRUPTIONS
    )}`;
    content += `${generateParagraph(
      REAL_TIME_LOG_BPOR_REPORT_COMMON_CONTENT.NOTHING_TO_REPORT
    )}<p />`;
  }

  const systemChangesReport: Record<
    SYSTEM_CHANGES_FIELD_LABEL,
    IRealTimeLogFieldModel
  >[] = [];
  sortedCompletedSystemChanges?.map((val) => {
    const extractedReport: any = val && extractReport(null, val);
    return extractedReport && systemChangesReport.push(extractedReport);
  });

  // Generate Content for System Changes
  if (sortedCompletedSystemChanges?.length) {
    systemChangesReport?.map((systemChangeReport, i) => {
      Object.keys(systemChangeReport).forEach((keyValue) => {
        const field =
          systemChangeReport[keyValue as SYSTEM_CHANGES_FIELD_LABEL];
        switch (keyValue) {
          case SYSTEM_CHANGES_FIELD_LABEL.SYSTEM_CHANGES: {
            if (i === 0) {
              content += generateHTMLContent(
                field.fieldTitle,
                field?.fieldValue,
                keyValue
              );
            }
            break;
          }
          case SYSTEM_CHANGES_FIELD_LABEL.SUBSTATION:
          case SYSTEM_CHANGES_FIELD_LABEL.TL: {
            if (field?.fieldValue !== NONE) {
              content += generateHTMLContent(
                field.fieldTitle,
                field?.fieldValue,
                keyValue
              );
            }
            break;
          }
          case SYSTEM_CHANGES_FIELD_LABEL.CHANGE:
            content += generateHTMLContent(
              field.fieldTitle,
              field?.fieldValue,
              keyValue
            );
            break;
          case SYSTEM_CHANGES_FIELD_LABEL.LER:
            content += generateHTMLContent(
              field.fieldTitle,
              field?.fieldValue,
              keyValue
            );
            if (
              sortedCompletedSystemChanges &&
              sortedCompletedSystemChanges?.length > 1 &&
              systemChangesReport.length - 1 !== i
            ) {
              content += '<hr />';
            }
            break;
          default:
            break;
        }
      });
    });
  } else {
    content += `${report.length ? '<p />' : ''}`;
    if (!(itemsOfInterestsReport.length && report.length)) {
      content += `${generateDefaultHeader(
        SYSTEM_CHANGES_FIELD_LABEL.SYSTEM_CHANGES
      )}`;
      content += `${generateParagraph(
        REAL_TIME_LOG_BPOR_REPORT_COMMON_CONTENT.NOTHING_TO_REPORT
      )}`;
    }
  }

  return {
    logId: 0,
    mailSubject: '',
    mailContent: content,
    mailHistoryContent: undefined,
  };
};

const deepEqual = (obj1, obj2) => {
  if (obj1 === obj2) return true; // Reference equality
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') return false; // Type check

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false; // Check number of keys

  return keys1.every((key) => deepEqual(obj1[key], obj2[key])); // Recursive check for each key
};

export const arraysEqualUnordered = (arr1, arr2) => {
  // Treat null and undefined as equal (empty)
  if (arr1 == null && arr2 == null) return true;
  if (arr1 == null || arr2 == null) return false;

  // Check length
  if (arr1.length !== arr2.length) return false;

  // Sort arrays based on stringified objects
  const sortedArr1 = [...arr1].sort((a, b) =>
    JSON.stringify(a).localeCompare(JSON.stringify(b))
  );

  const sortedArr2 = [...arr2].sort((a, b) =>
    JSON.stringify(a).localeCompare(JSON.stringify(b))
  );

  return sortedArr1.every((item, index) => deepEqual(item, sortedArr2[index])); // Deep compare each object
};

export const isEmailTurnedOff = (data: IRealTimeLog) => {
  if (data) {
    const auditLogs = data.auditLogs?.sort(
      (a, b) =>
        new Date(b?.updatedAt).getTime() - new Date(a?.updatedAt).getTime()
    );
    if (auditLogs && auditLogs?.length > 0) {
      if (auditLogs[0].auditType === RealTimeLogAuditCategory.EMAIL_TURNEDOFF) {
        return true;
      }
    } else {
      return true;
    }
  }
  return false;
};

export const canEditRealTimeLog = (category, userId) => {
  if (getIsAdmin(AccessRole.REAL_TIME_LOG_UPDATER)) {
    if (category === null) {
      return getUser()?.emp_no === userId?.replace(/^00/gm, '');
    }
    return true;
  }
  return false;
};
