import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  ATMButton,
  ATMForm,
  ATMFormProvider,
  ATMGrid,
  ATMLoader,
  ATMSegment,
  ATMTab,
  MOLRightPanel,
} from 'shared-it-appmod-ui';
import { AccessRole, AccessType } from 'src/constants';
import Lang from 'src/libraries/language';
import {
  ForcedOutagePage,
  ForcedOutageRequestFormStep,
} from 'src/constants/forced-outage.constants';
import { useLocationParams } from 'src/hooks/location-tab.hook';
import { hasAccess, hasRole } from 'src/libraries/access.library';
import ForcedOutageHeader from 'src/components/organisms/forced-outage-header/forced-outage-header.component';
import {
  ForcedOutageFormSchema,
  IForcedOutageForm,
  IForcedOutagePayload,
} from 'src/models/forced-outage.model';
import { useForcedOutageContext } from 'src/contexts/forced-outage.context';
import ForcedOutageInformationForm from '../forced-outage-information/forced-outage-information-form.component';
import ForcedOutageRestorationForm from '../forced-outage-restoration-information/forced-outage-restoration-information-form.component';
import ForcedOutageOverviewForm from '../forced-outage-overview/forced-outage-overview-form.component';
import AccessComponent from 'src/components/atoms/access/access.component';
import styles from './forced-outage-edit.module.scss';
import { getForcedOutageStatus } from 'src/selectors/forced-outage.selector';
import { forcedOutageListActionTypes } from 'src/ducks/forced-outage.duck';
import {
  ToastError,
  ToastSuccess,
} from 'src/components/atoms/toaster/toaster.component';
import { getUser } from 'src/libraries/amplify.library';
import { useSharepoint } from 'src/hooks/file.hook';
import { ISharepointRequestData } from 'src/models/sharepoint-service.model';
import { useFileContext } from 'src/contexts/file.context';
import { SharePointApplicationType } from 'src/constants/sharepoint-common.constant';
import { useSettingContext } from 'src/contexts/setting.context';
import FormError from 'src/components/atoms/form-error/form-error.component';

const scrollToTop = () => {
  const element = document.getElementById('form-error');
  if (element) {
    element.scrollIntoView({
      block: 'end',
      inline: 'nearest',
    });
  }
};

interface IProps {
  trigger?: React.ReactNode;
  activeTab?: ForcedOutageRequestFormStep;
  isEdit?: boolean;
  viewType?: string;
}

const Content: React.FC<
  IProps & {
    id: number;
    setClose: () => void;
  }
> = ({ id, setClose }) => {
  const {
    state,
    state: { data },
    actions,
  } = useForcedOutageContext();
  const { state: settingState } = useSettingContext();
  const { actions: fileAction, state: fileState } = useFileContext();
  const { submitUpload } = useSharepoint();

  const {
    // handleChange,
    // params: { active },
  } = useLocationParams([
    ForcedOutagePage.View,
    ForcedOutagePage.Edit,
    'active',
  ]);
  const formRef = useRef<HTMLFormElement>(null);
  const [tabIndex, setTabIndex] = useState<ForcedOutageRequestFormStep>(
    ForcedOutageRequestFormStep.OUTAGE_OVERVIEW
  );
  const [isFileCached, setIsFileCached] = useState<boolean>();
  const [isUpdated, setIsUpdated] = useState(false);

  const status = getForcedOutageStatus(
    state,
    forcedOutageListActionTypes.FORCED_OUTAGE_DATA_READ
  );

  const updateStatus = getForcedOutageStatus(
    state,
    forcedOutageListActionTypes.FORCED_OUTAGE_DATA_UPDATE
  );

  const removeUploadHistory = useCallback(() => {
    if (fileState.filename_list?.length) {
      fileAction.deleteAllStashedFiles();
    }
  }, [fileState, fileAction]);

  useEffect(() => {
    removeUploadHistory();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const result = await actions.dataGET(id ?? -1);

      if (result.error) {
        setClose();
      } else {
        // eslint-disable-next-line no-lonely-if
        if (result.payload) {
          actions.setPreviousData(result.payload as any);
        }
      }
    };
    fetchData();
  }, [id, actions, setClose]);

  const handleFileUpload = useCallback(
    async (responseData: IForcedOutagePayload) => {
      const uploader = `${getUser()?.first_name} ${getUser()?.last_name}`;
      const requestData: ISharepointRequestData = {
        accessToken: '',
        createdBy: uploader ?? '',
        documentId: '',
        fileId: '',
        fileName: '',
        folderName: responseData?.outageId || '',
        parentFolder: '',
        requestId: responseData?.outageId as any,
        requestor: uploader,
        documentLibrary: '',
      };
      await submitUpload({
        requestData,
        files: fileState.stash,
        applicationType: SharePointApplicationType.FORCED_OUTAGE,
        setting: settingState,
        withParentFolder: false,
        setIsUpdated,
      });
    },
    [fileState, settingState, setIsUpdated]
  );

  const handleClick = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [formRef]);

  const handleSubmit = useCallback(
    async (formData: IForcedOutageForm) => {
      const response = await actions.dataPUT(id, formData);
      if (response.error) {
        ToastError('Failed to update forced outage');
        removeUploadHistory();
      }
      if (response.payload) {
        handleFileUpload(response.payload);
        ToastSuccess('Successfully updated forced outage');
      }
    },
    [actions, handleFileUpload]
  );

  if (!data || status.fetching) {
    return <ATMLoader active />;
  }

  const panes = [
    {
      key: ForcedOutageRequestFormStep.OUTAGE_OVERVIEW,
      access: AccessType.FORCED_OUTAGE_KEARNY || AccessType.FORCED_OUTAGE_GOS,
      menuItem:
        Lang.FORCED_OUTAGE_REQUEST_STEP[
          ForcedOutageRequestFormStep.OUTAGE_OVERVIEW
        ],
    },
    {
      key: ForcedOutageRequestFormStep.OUTAGE_INFORMATION,
      access: AccessType.FORCED_OUTAGE_KEARNY || AccessType.FORCED_OUTAGE_GOS,
      menuItem:
        Lang.FORCED_OUTAGE_REQUEST_STEP[
          ForcedOutageRequestFormStep.OUTAGE_INFORMATION
        ],
    },
    {
      key: ForcedOutageRequestFormStep.RESTORATION_INFORMATION,
      access: AccessType.FORCED_OUTAGE_GOS,
      menuItem:
        Lang.FORCED_OUTAGE_REQUEST_STEP[
          ForcedOutageRequestFormStep.RESTORATION_INFORMATION
        ],
    },
  ].filter((v) => !v.access || hasAccess(v.access));
  return (
    <ATMForm
      key={`forced_outage_edit_${data?.id}`}
      ref={formRef}
      defaultValues={data as unknown as IForcedOutageForm}
      validationSchema={ForcedOutageFormSchema}
      mode="onChange"
      onError={scrollToTop}
      onSubmit={handleSubmit}
    >
      {(props) => {
        return (
          <ATMFormProvider {...props}>
            <div className={styles.header}>
              <ATMSegment attached>
                <ATMGrid columns={2}>
                  <ATMGrid.Column verticalAlign="middle">
                    <ForcedOutageHeader data={data} />
                  </ATMGrid.Column>
                  <ATMGrid.Column textAlign="right">
                    <ul className={styles.right}>
                      <li>
                        <div className="action-buttons">
                          {hasRole([
                            AccessRole.FORCED_OUTAGE_REQUESTOR,
                            AccessRole.FORCED_OUTAGE_RESOLVER,
                          ]) && (
                            <ATMButton
                              primary
                              type="button"
                              loading={updateStatus.fetching}
                              disabled={
                                !(props.formState.isDirty || isFileCached) ||
                                updateStatus.fetching
                              }
                              onClick={handleClick}
                            >
                              {/* {hasRole([AccessRole.FORCED_OUTAGE_REQUESTOR])
                                ? Lang.LBL_UPDATE
                                : Lang.LBL_SUBMIT} */}
                              {Lang.LBL_UPDATE}
                            </ATMButton>
                          )}
                        </div>
                      </li>
                      <li>
                        <div className="action-buttons">
                          <ATMButton
                            type="button"
                            color="grey"
                            basic
                            icon="external alternate"
                            onClick={() => {
                              window
                                .open(window.location.href, '_blank')
                                ?.focus();
                            }}
                          />

                          {setClose && (
                            <ATMButton
                              type="button"
                              color="grey"
                              basic
                              icon="close"
                              onClick={setClose}
                            />
                          )}
                        </div>
                      </li>
                    </ul>
                  </ATMGrid.Column>
                </ATMGrid>
              </ATMSegment>
              <ATMTab
                panes={panes}
                activeIndex={panes.findIndex((v) => v.key === tabIndex)}
                onTabChange={(_, value) => {
                  scrollToTop();
                  if (value.activeIndex !== undefined && value.panes) {
                    const key = value.panes[value.activeIndex]
                      .key as ForcedOutageRequestFormStep;
                    setTabIndex(key);
                  }
                }}
                menu={{ secondary: true, pointing: true }}
                renderActiveOnly={false}
              />
            </div>
            <div className="admin-container">
              <div id="form-error">
                <FormError
                  errors={props.formState.errors}
                  handleItemClick={(error) => {
                    setTabIndex(
                      error.name.split('.')[0] as ForcedOutageRequestFormStep
                    );
                  }}
                  dictionary="FORM_FORCED_OUTAGE"
                />
              </div>
              <AccessComponent
                type={AccessType.FORCED_OUTAGE_KEARNY}
                when={tabIndex === ForcedOutageRequestFormStep.OUTAGE_OVERVIEW}
              >
                <div
                  className={
                    tabIndex !== ForcedOutageRequestFormStep.OUTAGE_OVERVIEW
                      ? 'hidden'
                      : undefined
                  }
                >
                  <ForcedOutageOverviewForm />
                </div>
              </AccessComponent>
              <AccessComponent
                type={AccessType.FORCED_OUTAGE_KEARNY}
                when={
                  tabIndex === ForcedOutageRequestFormStep.OUTAGE_INFORMATION
                }
              >
                <div
                  className={
                    tabIndex !== ForcedOutageRequestFormStep.OUTAGE_INFORMATION
                      ? 'hidden'
                      : undefined
                  }
                >
                  <ForcedOutageInformationForm
                    setFileCached={setIsFileCached}
                    data={data as any}
                    isEdit
                    isUpdated={isUpdated}
                  />
                </div>
              </AccessComponent>
              <AccessComponent
                type={AccessType.FORCED_OUTAGE_GOS}
                when={
                  tabIndex ===
                  ForcedOutageRequestFormStep.RESTORATION_INFORMATION
                }
              >
                <div
                  className={
                    tabIndex !==
                    ForcedOutageRequestFormStep.RESTORATION_INFORMATION
                      ? 'hidden'
                      : undefined
                  }
                >
                  <ForcedOutageRestorationForm
                    setFileCached={setIsFileCached}
                    data={data as any}
                    isEdit
                    isUpdated={isUpdated}
                  />
                </div>
              </AccessComponent>
            </div>
          </ATMFormProvider>
        );
      }}
    </ATMForm>
  );
};

const ForcedOutageEdit: React.FC<IProps> = ({ trigger }) => {
  const { params: currentTab, handleChange } = useLocationParams([
    ForcedOutagePage.Edit,
    'active',
  ]);
  const id = Number(currentTab.forcedOutageEdit);

  return (
    <MOLRightPanel
      isOpen={!!id}
      onClose={() => {
        handleChange();
      }}
      onOpen={
        trigger
          ? () =>
              handleChange({
                ...currentTab,
                [ForcedOutagePage.Edit]: id,
              })
          : undefined
      }
      trigger={trigger}
      className={styles.floatingPanel}
      width="calc(100vw - 200px)"
      closeOnDimmerClick={false}
    >
      <Content
        id={id}
        setClose={() => {
          handleChange();
        }}
      />
    </MOLRightPanel>
  );
};

export default React.memo(ForcedOutageEdit);
