import React, { useCallback, useState } from 'react';
import { ATMHeader, ATMLabel, MOLRightPanel } from 'shared-it-appmod-ui';
import { AccessType } from 'src/constants';
import { ILerRequestDraft } from 'src/models/ler-request.model';
import Access from 'src/components/atoms/access/access.component';
import styles from './forced-outage-add.module.scss';
import Lang from 'src/libraries/language';
import ForcedOutageForm from '../forced-outage-form/forced-outage-form.component';
import { ToastSuccess } from 'src/components/atoms/toaster/toaster.component';
import { useFileContext } from 'src/contexts/file.context';
import { useForcedOutageContext } from 'src/contexts/forced-outage.context';
import { SharePointApplicationType } from 'src/constants/sharepoint-common.constant';
import { useSettingContext } from 'src/contexts/setting.context';
import { useSharepoint } from 'src/hooks/file.hook';
import { getUser } from 'src/libraries/amplify.library';
import {
  IForcedOutageForm,
  IForcedOutagePayload,
  // IForceOutageFormDraft,
} from 'src/models/forced-outage.model';
import { ISharepointRequestData } from 'src/models/sharepoint-service.model';
// import { getAccessRole } from 'src/libraries/access.library';
import { useFacilityContext } from 'src/contexts/facility.context';

type IContentProps = {
  setClose: () => void;
};

const Content: React.FC<IContentProps> = ({ setClose }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { actions } = useForcedOutageContext();
  const { state: settingState } = useSettingContext();
  const { actions: fileAction, state: fileState } = useFileContext();
  const { submitUpload } = useSharepoint();

  const { actions: facilityActions } = useFacilityContext();

  // const [draft, setDraft] = useState<
  //   Partial<IForceOutageFormDraft> | undefined
  // >();

  const removeUploadHistory = useCallback(() => {
    if (fileState.filename_list?.length) {
      fileAction.deleteAllStashedFiles();
    }
  }, [fileState, fileAction]);

  const handleFileUpload = useCallback(
    async (responseData: IForcedOutagePayload) => {
      const uploader = `${getUser()?.first_name} ${getUser()?.last_name}`;
      const requestData: ISharepointRequestData = {
        accessToken: '',
        createdBy: uploader ?? '',
        documentId: '',
        fileId: '',
        fileName: '',
        folderName: JSON.stringify(responseData?.id) || '',
        parentFolder: JSON.stringify(responseData?.id) || '',
        requestId: responseData?.id as any,
        requestor: uploader,
        documentLibrary: '',
      };
      await submitUpload({
        requestData,
        files: fileState.stash,
        applicationType: SharePointApplicationType.FORCED_OUTAGE,
        setting: settingState,
        withParentFolder: true,
      });
    },
    [fileState, settingState]
  );

  const handleSubmit = useCallback(
    async (formData: IForcedOutageForm) => {
      setIsLoading(true);
      const result = await actions.createPOST(formData);
      if (result.payload) {
        await handleFileUpload(result.payload);
        removeUploadHistory();
      }
      if (result.error) {
        removeUploadHistory();
      }

      // if (draft && draft.outageRequestDraftId) {
      //   await actions.draftDELETE(draft.outageRequestDraftId);
      // }

      if (!result.error) {
        setClose();
        // handleChange({
        //   [LERRequestPage.View]: result.payload?.requestId,
        //   [LERRequestPage.Clone]: undefined,
        // });
        ToastSuccess(Lang.MSG_FORCED_OUTAGE_CREATE_SUCCESS);

        // if (
        //   result.payload?.requestStat === LERRequestStatus.Approved &&
        //   hasRole(AccessRole.MCC_OUTAGE_SCHEDULER)
        // ) {
        //   actions.setForApproval(true);
        // }
      }
      setIsLoading(false);
    },
    [actions, setClose, setIsLoading, handleFileUpload, fileState, fileAction]
  );

  // const handleSave = useCallback(
  //   async (formData: Partial<IForcedOutageForm>) => {
  //     setIsLoading(true);
  //     // console.log('test draft', draft);
  //     // console.log('test data', formData);
  //     // if (draft && draft.id) {
  //     //   // const firstValues = getValues();

  //     //   const data = {
  //     //     ...draft,
  //     //     ...formData,
  //     //   };
  //     //   const result = await actions.draftPut(draft?.id, data);
  //     //   console.log('test result in saving', result);

  //     //   if (result.payload) {
  //     //     setDraft(result.payload as unknown as Partial<IForceOutageFormDraft>);
  //     //   }
  //     //   if (!result.error) {
  //     //     ToastSuccess(Lang.MSG_FORCED_OUTAGE_SAVE_SUCCESS);
  //     //   }
  //     // } else {
  //     //   const result = await actions.draftPost(formData);
  //     //   console.log('test result in saving', result);

  //     //   if (result.payload) {
  //     //     setDraft(result.payload as unknown as Partial<IForceOutageFormDraft>);
  //     //   }
  //     //   if (!result.error) {
  //     //     ToastSuccess(Lang.MSG_FORCED_OUTAGE_SAVE_SUCCESS);
  //     //   }
  //     // }

  //     setIsLoading(false);
  //   },
  //   [
  //     actions,
  //     setClose,
  //     // setDraft,
  //     // draft,
  //     setIsLoading,
  //     fileState,
  //     fileAction,
  //     getAccessRole,
  //   ]
  // );

  const clearFacilityData = useCallback(() => {
    facilityActions?.dataCLEAR();
  }, [actions]);

  return (
    <ForcedOutageForm
      header={
        <ul>
          <li>
            <ATMHeader>{Lang.TTL_NEW_FORCED_OUTAGE}</ATMHeader>
          </li>
          <li>
            <ATMLabel className={styles.labelDraft}>{Lang.TTL_DRAFT}</ATMLabel>
          </li>
        </ul>
      }
      defaultValues={undefined}
      handleClose={() => {
        setClose && setClose();
        clearFacilityData();
      }}
      // handleSave={handleSave}
      handleSubmit={handleSubmit}
      loading={isLoading}
      // draftId={}
    />
  );
};

type IProps = {
  data?: Partial<ILerRequestDraft>;
  open?: boolean;
  trigger?: React.ReactNode;
  onClose?: () => void;
};

const ForcedOutageRequestAdd: React.FC<IProps> = ({
  open,
  trigger,
  onClose,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    if (onClose) {
      onClose();
    }
  }, [onClose, setIsOpen]);

  return (
    <Access type={AccessType.FORCED_OUTAGE_GOS}>
      <MOLRightPanel
        id="forced-outage-form"
        isOpen={open || isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={handleClose}
        trigger={trigger}
        className={styles.floatingPanel}
        width="calc(100vw - 200px)"
        closeOnDimmerClick={false}
      >
        {(open || isOpen) && <Content setClose={handleClose} />}
      </MOLRightPanel>
    </Access>
  );
};

export default ForcedOutageRequestAdd;
