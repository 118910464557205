import { ICommonState, IReducerAction } from 'src/libraries/thunk.library';
import {
  IOutageSharedInfoPayload,
  IForcedOutage,
  IForcedOutagePayload,
  ICauseCode,
} from 'src/models/forced-outage.model';
import services from 'src/services/forced-outage.service';

export const forcedOutageListActionTypes = {
  FORCED_OUTAGE_DATA_READ: 'FORCED_OUTAGE_DATA_READ',
  FORCED_OUTAGE_LIST_DATA_READ: 'FORCED_OUTAGE_LIST_DATA_READ',
  FORCED_OUTAGE_SEARCH_LIST_DATA_READ: 'FORCED_OUTAGE_SEARCH_LIST_DATA_READ',
  FORCED_OUTAGE_DATA_CREATE: 'FORCED_OUTAGE_DATA_CREATE',
  FORCED_OUTAGE_DATA_UPDATE: 'FORCED_OUTAGE_DATA_UPDATE',
  FORCED_OUTAGE_DATA_REJECT: 'FORCED_OUTAGE_DATA_REJECT',
  FORCED_OUTAGE_DATA_APPROVE: 'FORCED_OUTAGE_DATA_APPROVE',
  FORCED_OUTAGE_DATA_CLEAR_LIST: 'FORCED_OUTAGE_DATA_CLEAR_LIST',
  FORCED_OUTAGE_PREVIOUS_DATA: 'FORCED_OUTAGE_PREVIOUS_DATA',
  FORCE_OUTAGE_LIST_CAUSE_CODE: 'FORCE_OUTAGE_LIST_CAUSE_CODE',
} as const;

export const duckActions = {
  // These are async actions that has promise response on event queue
  dataGET: {
    type: forcedOutageListActionTypes.FORCED_OUTAGE_DATA_READ,
    service: services.dataGET,
  },
  listGET: {
    type: forcedOutageListActionTypes.FORCED_OUTAGE_LIST_DATA_READ,
    service: services.listGET,
  },
  searchListGET: {
    type: forcedOutageListActionTypes.FORCED_OUTAGE_SEARCH_LIST_DATA_READ,
    service: services.listGET,
  },
  createPOST: {
    type: forcedOutageListActionTypes.FORCED_OUTAGE_DATA_CREATE,
    service: services.createPOST,
  },
  dataPUT: {
    type: forcedOutageListActionTypes.FORCED_OUTAGE_DATA_UPDATE,
    service: services.updatePUT,
  },
  approvePUT: {
    type: forcedOutageListActionTypes.FORCED_OUTAGE_DATA_APPROVE,
    service: services.approvePUT,
  },
  rejectPUT: {
    type: forcedOutageListActionTypes.FORCED_OUTAGE_DATA_REJECT,
    service: services.rejectPUT,
  },
  clearList: () => ({
    type: forcedOutageListActionTypes.FORCED_OUTAGE_DATA_CLEAR_LIST,
  }),
  setPreviousData: (previousForcedOutageData: IOutageSharedInfoPayload) => ({
    type: forcedOutageListActionTypes.FORCED_OUTAGE_PREVIOUS_DATA,
    payload: previousForcedOutageData,
  }),
  getListCauseCd: {
    type: forcedOutageListActionTypes.FORCE_OUTAGE_LIST_CAUSE_CODE,
    service: services.getListCauseCd,
  },
};

export type IForcedOutageAsync = typeof duckActions;

export interface IForcedOutageState
  extends ICommonState<typeof forcedOutageListActionTypes> {
  data?: IForcedOutagePayload;
  outageData?: IOutageSharedInfoPayload | IForcedOutage;
  list: IForcedOutage[];
  searchList: IForcedOutage[];
  previousData: IOutageSharedInfoPayload | IForcedOutagePayload;
  causeCdList: ICauseCode[];
}

export const defaultState: IForcedOutageState = {
  status: {},
  list: [],
  searchList: [],
  previousData: {} as any,
  causeCdList: [],
};

const ForcedOutageReducer = (
  state: IForcedOutageState,
  action: IReducerAction<IForcedOutageAsync>
): IForcedOutageState => {
  switch (action.type) {
    case forcedOutageListActionTypes.FORCED_OUTAGE_DATA_CREATE:
    case forcedOutageListActionTypes.FORCED_OUTAGE_DATA_UPDATE:
    case forcedOutageListActionTypes.FORCED_OUTAGE_DATA_REJECT:
    case forcedOutageListActionTypes.FORCED_OUTAGE_DATA_APPROVE:
    case forcedOutageListActionTypes.FORCED_OUTAGE_DATA_READ: {
      return {
        ...state,
        data: action.payload,
      };
    }

    case forcedOutageListActionTypes.FORCED_OUTAGE_SEARCH_LIST_DATA_READ: {
      return {
        ...state,
        searchList: action.payload?.rows ?? [],
      };
    }

    case forcedOutageListActionTypes.FORCED_OUTAGE_LIST_DATA_READ: {
      return {
        ...state,
        list: action.payload?.rows ?? [],
      };
    }

    case forcedOutageListActionTypes.FORCED_OUTAGE_PREVIOUS_DATA: {
      return {
        ...state,
        previousData: action.payload as unknown as IOutageSharedInfoPayload,
      };
    }

    case forcedOutageListActionTypes.FORCED_OUTAGE_DATA_CLEAR_LIST: {
      return {
        ...state,
        list: [],
        searchList: [],
      };
    }

    // case forcedOutageListActionTypes.FORCE_OUTAGE_LIST_CAUSE_CODE: {
    //   return {
    //     ...state,
    //     causeCdList: action.payload ?? [],
    //   };
    // }

    default: {
      return state;
    }
  }
};

export default ForcedOutageReducer;
