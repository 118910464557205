import React, { useCallback, useEffect, useState } from 'react';
import {
  ATMDatePicker,
  ATMDropdown,
  ATMField,
  ATMForm,
  ATMGrid,
  ATMInput,
  ATMSelect,
  ATMTextArea,
  formatTime,
  useATMFormContext,
} from 'shared-it-appmod-ui';
import LabelRequired from 'src/components/atoms/label-required/label-required.component';
import { ForcedOutageFacilityType } from 'src/constants/forced-outage.constants';
import { useEquipmentTypeContext } from 'src/contexts/equipment-type.context';
import { useFacilityContext } from 'src/contexts/facility.context';
import { useRealTimeLogContext } from 'src/contexts/real-time-log.context';
import { useSubstationContext } from 'src/contexts/substation.context';
import { equipmentTypeActionTypes } from 'src/ducks/equipment-type.duck';
import { facilityActionTypes } from 'src/ducks/facility.duck';
import { realTimeLogActionTypes } from 'src/ducks/real-time-log.duck';
import { substationActionTypes } from 'src/ducks/substation.duck';
import Lang from 'src/libraries/language';
import { IForcedOutageForm } from 'src/models/forced-outage.model';
import { getEquipmentTypeStatus } from 'src/selectors/equipment-type.selector';
import { getFacilityStatus } from 'src/selectors/facility.selector';
import { getRealTimeLogStatus } from 'src/selectors/real-time-log.selector';
import { getSubstationStatus } from 'src/selectors/substation.selector';
import {
  HourTimePattern,
  restrictAlphabetsAndSpecialChars,
} from 'src/components/atoms/input/time-input-switching-format.component';
import { debounce } from 'lodash';
import { causeCodeValues } from 'src/constants/causeCodes';

type IProps = {
  isEdit?: boolean;
};

export const causeCdOptions = causeCodeValues.map((code) => {
  return {
    key: code.causeCd,
    value: code.causeCd,
    text: `${code.causeCd} - ${code.description}`,
  };
});

const ForcedOutageOverviewForm: React.FC<Partial<IProps>> = () => {
  const {
    control,
    formState: { errors },
    resetField,
    getValues,
    setValue,
    setError,
  } = useATMFormContext<IForcedOutageForm>();
  const [maxDate] = useState<Date | undefined>(undefined);

  const [, setOutgFacId] = useState<number | undefined>(
    getValues().outgFacId ?? undefined
  );

  const [equipId, setEquipId] = useState<number | undefined>(
    getValues()?.facility?.facTyp?.facTypId
  );

  const [facilityTypeId, setFacilityTypeId] = useState<number | undefined>(
    () => {
      const type = getValues().facilityTypeId;
      if (type) {
        return type;
      }
      // eslint-disable-next-line no-nested-ternary
      return getValues().substationId
        ? ForcedOutageFacilityType.Station
        : equipId
        ? ForcedOutageFacilityType.Line
        : undefined;
    }
  );

  const [equipType, setEquipType] = useState<string | undefined>(
    getValues().equipType ?? undefined
  );
  const [substationId, setSubstationId] = useState<string | undefined>(
    getValues().substationId ?? undefined
  );
  const [voltage, setVoltage] = useState<string | undefined>(
    getValues().voltage ?? undefined
  );

  const [voltId, setVoltId] = useState<number | undefined>(
    getValues().facility?.volt?.voltId ?? undefined
  );

  const { state: equipmentTypeState, actions: equipmentTypeActions } =
    useEquipmentTypeContext();
  const { state: substationState, actions: substationActions } =
    useSubstationContext();
  const { state: facilityState, actions: facilityActions } =
    useFacilityContext();
  const { state: realtimeLogState, actions: realtimeLogActions } =
    useRealTimeLogContext();

  const realTimeLogStatus = getRealTimeLogStatus(
    realtimeLogState,
    realTimeLogActionTypes.REAL_TIME_LOG_LINE_READ
  );

  const substationStatus = getSubstationStatus(
    substationState,
    substationActionTypes.SUBSTATION_LIST_CREATE_READ
  );

  const equipmentTypeStatus = getEquipmentTypeStatus(
    equipmentTypeState,
    equipmentTypeActionTypes.EQUIPMENT_TYPE_LIST_READ
  );

  const voltStatus = getFacilityStatus(
    facilityState,
    facilityActionTypes.FACILITY_VOLT_SUBS_READ
  );

  const equipmentStatus = getFacilityStatus(
    facilityState,
    facilityActionTypes.FACILITY_EQUIPMENT_READ
  );

  useEffect(() => {
    if (!substationState.listForCreate.length) {
      substationActions.listSubstationCreateGET({
        limit: 0,
        page: 1,
      });
    }
    if (!realtimeLogState.line.length) {
      realtimeLogActions.lineGET();
    }
  }, [substationActions, realtimeLogActions]);

  useEffect(() => {
    if (substationId && !equipmentTypeState.list.length) {
      equipmentTypeActions.listGET({
        limit: 0,
        page: 1,
        filters: [{ name: 'substationId', value: substationId }],
      });
    }
  }, [substationId, equipmentTypeActions]);

  useEffect(() => {
    if (equipId && substationId && !facilityState.volt.length) {
      facilityActions.voltSubstationGET(substationId, equipId);
    }
  }, [equipId, substationId, facilityActions]);

  const handleDefaultRestoSubs = useCallback(
    async (facilityId: number) => {
      facilityActions.dataCLEAR();
      const res = await facilityActions.dataGET(facilityId);
      if (res.payload) {
        setValue('facility.facTyp.facTypId', res.payload?.facTypId);
        setValue('facility.outgFacNm', res.payload?.outgFacNm);
        setValue('facility.outgFacId', res.payload?.outgFacId);
        setValue('facility.facTyp.facTypNm', res.payload?.facTyp?.facTypNm);
        setValue('facility.volt.voltId', res.payload?.volt?.voltId);
        setValue('facility.volt.voltNm', res.payload?.volt?.voltNm);
        setValue(
          'restorationInformation.restorationSub',
          res.payload.substations.map((i) => {
            return { substationId: i.substationId };
          })
        );
      }
    },
    [facilityActions, facilityState]
  );

  useEffect(() => {
    if (equipId && substationId && voltId) {
      facilityActions.equipmentGET(equipId, voltId, undefined, substationId);
      if (getValues()?.facility?.outgFacId) {
        handleDefaultRestoSubs(getValues()?.facility?.outgFacId as number);
      }
    }
  }, [equipId, substationId, voltId, facilityActions]);

  const handleTimeUpdate = useCallback(
    debounce((value, name) => {
      const input = document.getElementsByName(name)[0] as HTMLInputElement;
      const nativeInputValueSetter = Object?.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        'value'
      )?.set;
      nativeInputValueSetter?.call(input, value);
      const ocEvent = new Event('input', { bubbles: true });
      input?.dispatchEvent(ocEvent);

      if (input && value) {
        input.setSelectionRange(value.length, value.length);
      }
      input?.focus();
    }, 50),
    []
  );

  return (
    <ATMGrid columns={2} style={{ maxWidth: 850 }}>
      <ATMGrid.Column>
        <ATMForm.Field>
          <LabelRequired>{Lang.LBL_FACILITY_TYPE}</LabelRequired>
          <ATMField
            as={ATMSelect}
            placeholder={Lang.LBL_SELECT}
            control={control}
            name="facilityTypeId"
            error={errors.facilityTypeId}
            value={facilityTypeId}
            defaultValue={facilityTypeId}
            options={Object.values(ForcedOutageFacilityType)
              .filter((val) => typeof val === 'number')
              .map((value, key) => ({
                key,
                value,
                text: Lang.FACILITY_TYPE[value],
              }))}
            onChange={
              (([_, { value }]) => {
                equipmentTypeActions.listCLEAR();
                facilityActions.voltCLEAR();
                facilityActions.equipmentCLEAR();
                setFacilityTypeId(value);
                setEquipType(undefined);
                setVoltage(undefined);
                setOutgFacId(undefined);

                setImmediate(() => {
                  resetField('outgFacId', {
                    defaultValue: null as any,
                  });
                  resetField('facility', {
                    defaultValue: null as any,
                  });
                  // resetField('facility.facTyp.facTypId', {
                  //   defaultValue: null as any,
                  // });
                  // resetField('facility.volt.voltId', {
                  //   defaultValue: null as any,
                  // });
                  setImmediate(() => {
                    resetField('substationId', {
                      defaultValue: null as any,
                    });
                    resetField('voltage', {
                      defaultValue: null as any,
                    });
                  });
                });

                return value;
              }) as any
            }
            clearable
            selectOnBlur={false}
          />
        </ATMForm.Field>
      </ATMGrid.Column>
      <ATMGrid.Column>
        {facilityTypeId === ForcedOutageFacilityType.Line ? (
          <ATMField
            key={`type_${facilityTypeId}`}
            name="outgFacId"
            label={<LabelRequired>{Lang.LBL_LINE}</LabelRequired>}
            placeholder={Lang.LBL_SELECT}
            as={ATMSelect}
            control={control}
            error={errors.outgFacId}
            options={realtimeLogState.line.map((value) => ({
              key: value.outgFacId,
              value: value.outgFacId,
              text: value.outgFacNm,
            }))}
            onChange={([_, { value }]) => {
              const facility = realtimeLogState.line.find(
                (i) => i.outgFacId === value
              );
              setOutgFacId(value);
              setVoltId(facility?.voltId);
              setEquipId(facility?.facTypId);
              handleDefaultRestoSubs(value);

              return value;
            }}
            clearable
            search
            selectOnBlur={false}
            disabled={
              (!realtimeLogState.line.length && realTimeLogStatus.fetching) ||
              !facilityTypeId
            }
            loading={
              !realtimeLogState.line.length && realTimeLogStatus.fetching
            }
          />
        ) : (
          <ATMField
            name="substationId"
            label={<LabelRequired>{Lang.LBL_SUBSTATION}</LabelRequired>}
            placeholder={Lang.LBL_SELECT}
            as={ATMSelect}
            control={control}
            error={errors.substationId}
            options={substationState.listForCreate.map((value) => ({
              key: value.substationId,
              value: value.substationId,
              text: value.name,
            }))}
            onChange={([_, { value }]) => {
              equipmentTypeActions.listCLEAR();
              facilityActions.voltCLEAR();
              facilityActions.equipmentCLEAR();
              facilityActions.dataCLEAR();

              setSubstationId(value);
              setEquipType(undefined);
              setVoltage(undefined);
              setOutgFacId(undefined);

              setImmediate(() => {
                resetField('facility', {
                  defaultValue: null as any,
                });
                // resetField('equipId', {
                //   defaultValue: null as any,
                // });
                resetField('voltage', {
                  defaultValue: null as any,
                });
                // resetField('equipType', {
                //   defaultValue: null as any,
                // });
              });
              return value;
            }}
            clearable
            search
            selectOnBlur={false}
            disabled={
              (!substationState.listForCreate.length &&
                substationStatus.fetching) ||
              !facilityTypeId
            }
            loading={
              !substationState.listForCreate.length && substationStatus.fetching
            }
          />
        )}
      </ATMGrid.Column>
      {facilityTypeId === ForcedOutageFacilityType.Station ? (
        <>
          <ATMGrid.Column>
            <ATMField
              key={`equipment_type_${substationId}_${equipmentTypeState.list.length}`}
              name="facility.facTyp.facTypId"
              label={<LabelRequired>{Lang.LBL_EQUIPMENT_TYPE}</LabelRequired>}
              placeholder={Lang.LBL_SELECT}
              as={ATMSelect}
              control={control}
              error={errors.equipId}
              options={equipmentTypeState.list.map((value) => ({
                key: value.facTypId,
                value: value.facTypId,
                text: value.facTypNm,
              }))}
              onChange={([_, { value }]) => {
                facilityActions.voltCLEAR();
                facilityActions.equipmentCLEAR();

                const getEquipNm = equipmentTypeState.list.find(
                  (i) => i.facTypId === value
                );

                setValue('equipType', getEquipNm?.facTypNm);
                setValue('facility.facTyp.facTypId', getEquipNm?.facTypId);
                setEquipId(value);
                setVoltage(undefined);
                setOutgFacId(undefined);

                setImmediate(() => {
                  resetField('voltage', {
                    defaultValue: null as any,
                  });
                });

                return value;
              }}
              clearable
              search
              selectOnBlur={false}
              disabled={
                equipmentTypeStatus.fetching || !facilityTypeId || !substationId
              }
              loading={equipmentTypeStatus.fetching}
            />
          </ATMGrid.Column>
          <ATMGrid.Column>
            <ATMField
              key={`volt_${substationId}_${equipType}`}
              name="facility.volt.voltId"
              label={
                <LabelRequired>{Lang.LBL_EQUIPMENT_VOLTAGE}</LabelRequired>
              }
              placeholder={Lang.LBL_SELECT}
              as={ATMSelect}
              control={control}
              error={errors.voltage}
              options={facilityState.volt.map((value) => ({
                key: value.voltId,
                value: value.voltId,
                text: value.name,
              }))}
              onChange={([_, { value }]) => {
                facilityActions.equipmentCLEAR();
                const volt = facilityState.volt.find((v) => v.name === value);
                setVoltId(volt?.voltId);
                setValue('facility.volt.voltId', volt?.voltId);
                setValue('facility.volt.voltNm', volt?.name);
                setVoltage(volt?.name);
                setOutgFacId(undefined);
                return value;
              }}
              selectOnBlur={false}
              disabled={voltStatus.fetching || !equipId}
              loading={voltStatus.fetching}
              search
              clearable
            />
          </ATMGrid.Column>
          <ATMGrid.Column>
            <ATMField
              key={`equipment_${substationId}_${equipType}_${voltage}`}
              as={ATMDropdown}
              selection
              name="outgFacId"
              label={<LabelRequired>{Lang.LBL_EQUIPMENT}</LabelRequired>}
              placeholder={Lang.LBL_SELECT}
              control={control}
              clearable
              error={errors.outgFacId}
              options={facilityState.equipment?.map((val) => ({
                key: val.outgFacId,
                value: val.outgFacId,
                text: val.outgFacNm,
              }))}
              onChange={([_, { value }]) => {
                if (!value) {
                  setOutgFacId(undefined);
                }
                const facility = facilityState.equipment.find(
                  (i) => i.outgFacId === value
                );
                setOutgFacId(value);
                setValue('facility.outgFacNm', facility?.outgFacNm ?? '');
                handleDefaultRestoSubs(value);
                return value;
              }}
              selectOnBlur={false}
              disabled={equipmentStatus.fetching || !voltage}
              loading={equipmentStatus.fetching}
              search
            />
          </ATMGrid.Column>
        </>
      ) : null}
      <ATMGrid.Row>
        <ATMGrid.Column width={4}>
          <ATMField
            name="startDate"
            label={
              <LabelRequired>
                {Lang.LBL_FORCED_OUTAGE_START_DATE_AND_TIME}
              </LabelRequired>
            }
            placeholder={Lang.LBL_SELECT}
            as={ATMDatePicker}
            format="MM/DD/YYYY"
            clearable
            dateTimePlaceHolder="Select"
            control={control}
            error={errors.startDate}
            maxDate={maxDate ?? new Date()}
            onChange={([_, { value }]) => {
              return value;
            }}
          />
        </ATMGrid.Column>
        <ATMGrid.Column width={4}>
          <ATMField
            name="startTm"
            as={ATMInput}
            label={<label>&nbsp;</label>}
            control={control}
            autoComplete="off"
            maxLength={5}
            error={errors.startTm}
            placeholder="hh:mm"
            clearable
            onChange={([_, { value }]) => {
              let formattedTime =
                value && value.length > 3
                  ? formatTime(restrictAlphabetsAndSpecialChars(value))
                  : restrictAlphabetsAndSpecialChars(value);

              if (formattedTime) {
                if (formattedTime.length > 4) {
                  handleTimeUpdate(formattedTime, 'startTm');
                }

                if (
                  formattedTime.match(HourTimePattern.HOUR_TIME_PATTERN as any)
                ) {
                  const hour = Number(formattedTime.split(':')[0]);
                  const min = Number(formattedTime.split(':')[1]);
                  if (!(hour > 23 || min > 59)) {
                    formattedTime = `${hour}:${min}`;
                  } else {
                    // formattedTime = null;
                    // setValue('startTm', '');
                    setError(`startTm`, {
                      message: 'Invalid Time',
                    });
                  }
                }
              }
              return formattedTime;
            }}
          />
        </ATMGrid.Column>

        <ATMGrid.Column width={4}>
          <ATMField
            name="endDate"
            label={Lang.LBL_FORCED_OUTAGE_END_DATE_AND_TIME}
            // label={<LabelRequired>{Lang.LBL_END_DATE_TIME}</LabelRequired>}
            placeholder={Lang.LBL_SELECT}
            as={ATMDatePicker}
            isDateTimePicker
            clearable
            format="MM/DD/YYYY"
            dateTimePlaceHolder="Select"
            control={control}
            minDate={getValues().startDate}
            maxDate={maxDate ?? new Date()}
            onChange={([_, { value }]) => {
              return value;
            }}
          />
        </ATMGrid.Column>

        <ATMGrid.Column width={4}>
          <ATMField
            name="endTm"
            as={ATMInput}
            label={<label>&nbsp;</label>}
            control={control}
            autoComplete="off"
            maxLength={5}
            placeholder="hh:mm"
            clearable
            onChange={([_, { value }]) => {
              let formattedTime =
                value && value.length > 3
                  ? formatTime(restrictAlphabetsAndSpecialChars(value))
                  : restrictAlphabetsAndSpecialChars(value);

              if (formattedTime) {
                if (formattedTime.length > 4) {
                  handleTimeUpdate(formattedTime, 'endTm');
                }

                if (
                  formattedTime.match(HourTimePattern.HOUR_TIME_PATTERN as any)
                ) {
                  const hour = Number(formattedTime.split(':')[0]);
                  const min = Number(formattedTime.split(':')[1]);
                  if (!(hour > 23 || min > 59)) {
                    formattedTime = `${hour}:${min}`;
                  } else {
                    setError(`endTm`, {
                      message: 'Invalid Time',
                    });
                  }
                } else {
                  formattedTime = null;
                }
              }
              return formattedTime;
            }}
          />
        </ATMGrid.Column>
      </ATMGrid.Row>
      <ATMGrid.Column>
        <ATMField
          name="causeCd"
          control={control}
          as={ATMDropdown}
          selection
          clearable
          label={Lang.LBL_FORCED_OUTAGE_CAUSE_CODE}
          options={causeCdOptions}
          onChange={([_, { value }]) => {
            return value;
          }}
        />
      </ATMGrid.Column>
      <ATMGrid.Column>
        <ATMField
          name="suppCauseCd"
          as={ATMDropdown}
          control={control}
          selection
          clearable
          label={Lang.LBL_FORCED_OUTAGE_SUPPLEMENTAL_CC}
          options={causeCdOptions}
          onChange={([_, { value }]) => {
            return value;
          }}
        />
      </ATMGrid.Column>
      <ATMGrid.Column width={16}>
        <ATMField
          name="description"
          as={ATMTextArea}
          control={control}
          label={Lang.LBL_DESCRIPTION}
        />
      </ATMGrid.Column>
    </ATMGrid>
  );
};

export default ForcedOutageOverviewForm;
