import React from 'react';
import { ForcedOutageRequestFormStep } from 'src/constants/forced-outage.constants';
import {
  IForcedOutage,
  IForcedOutageForm,
} from 'src/models/forced-outage.model';
import ForcedOutageDocument from '../forced-outage-documents/forced-outage-documents.component';
import { ATMDivider, useATMFormContext } from 'shared-it-appmod-ui';
import ForcedOutageCommentsForm from '../forced-outage-comments/forced-outage-comments-form.component';
import ForcedOutagePanelOverview from '../forced-outage-overview/forced-outage-panel-overview.component';

type IProps = {
  data?: IForcedOutage | null;
  isEdit?: boolean;
  isUpdated?: boolean;
  handleEdit?: (step: ForcedOutageRequestFormStep) => void;
  handleDelete?: (key: string) => void;
  setFileCached?: (data: boolean) => void;
};
const ForcedOutagePanel: React.FC<IProps> = ({
  handleEdit,
  setFileCached,
  data,
  isEdit = false,
  isUpdated,
}) => {
  const { getValues } = useATMFormContext<IForcedOutageForm>();

  return (
    <>
      {/* Overview */}
      <ForcedOutagePanelOverview
        data={getValues()}
        handleEdit={
          handleEdit
            ? () => handleEdit(ForcedOutageRequestFormStep.OUTAGE_OVERVIEW)
            : undefined
        }
      />
      <ATMDivider />

      {/* Document */}
      <ForcedOutageDocument
        data={data}
        isEdit={isEdit}
        setFileCached={setFileCached}
        isUpdated={isUpdated}
      />
      <ATMDivider />

      {/* Comments */}
      <ForcedOutageCommentsForm data={getValues()} />
    </>
  );
};

export default ForcedOutagePanel;
